import { useParams } from 'react-router-dom';
import './PaymentDetail.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { PaymentType } from 'src/api/models';
import { useGetPaymentByCode } from 'src/api/paymentApi';
import { useAddPopup } from 'src/state/application/hooks';
import { EnumPaymentStatus } from 'src/common/enum/EnumPaymentStatus';

const PaymentDetail: React.FC = () => {
  const getPaymentByCode = useGetPaymentByCode();

  const [payment, setPayment] = useState<PaymentType>();
  const addPopup = useAddPopup();
  const { code } = useParams();

  useEffect(() => {
    if(code) {
      getPaymentByCode(code).then((res) => {
        setPayment(res);
      }).catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
      })
    }
  }, [addPopup, getPaymentByCode, code])


  return (<>
      <div className='payment-detail-content'
        dangerouslySetInnerHTML={{
          __html: payment?.paymentContent,
        }}
      />
      {payment?.status == EnumPaymentStatus.Paid ? <div className='paid-payment'>ĐÃ THANH TOÁN</div> : null}
    </>
  );
};

export default PaymentDetail;
