export type StatisticType = 'price' | 'supply' | 'marketcap';

export type OptionType = {
  title: string;
  value: any;
  css?: any;
};

export enum EnumDataType {
  Text = 1,
  Int = 2,
  Date = 3,
  PhoneNumber = 4,
  Email = 5,
  Boolean = 6,
  Percentage = 7,
  BigInt = 8,
  JPY = 9,
  Month = 10,
  QuarterOfYear = 11,
  Year = 12,
  DateRange = 13,
  Image = 14,
  Option = 15,
  HTML = 16,
  Ratio = 17,
  Link=18,
  Decimal = 19,
  VND = 20
}

export type PopupContent = {
  txn?: {
    success: boolean;
    summary?: string;
  };
  error?: {
    message: string;
    title: string;
  };
  context?: {
    width?: string;
    height?: string;
    listActionButton: EventButton[];
    posX?: string;
    posY?: string;
  };
};

export type PopupList = Array<{
  key: string;
  //show: boolean;
  content: PopupContent;
  removeAfterMs: number | null;
}>;

export type ProfileInfo = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  userId?: number;
  birthday?: string;
  email?: string;
  fullName?: string;
  loginName?: string;
  telephone?: string;
  avata?: string;
  avataUrl?: string;
  password?: string;
  displayOrder?: number;
  groupId?: number;
  langId?: number;
  currentPassword?: string;
  confirmPassword?: string;
  mainAddress?: string;
  facebookId?: string;
  googleId?: string;
  appleId?: string;
  twitterId?: string;
  cmtnd?: string;
  cccd?: string;
  mobile?: string;
  sex?: null;
  occupation?: null;
  fbAccessToken?: string;
  googleAccessToken?: string;
  appleAccessToken?: string;
  cover?: string;
  loginNameHash?: string;
  refreshToken?: null;
  otp?: string;
};

export type ParameterType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  parameterId?: number;
  parameterTitle?: string;
  parameterKey?: string;
  parameterValue?: string;
  effectiveDate?: string;
};

export type Profile = {
  accessToken: string;
  refreshToken: string;
  info?: ProfileInfo;
};

export type PageData<T> = {
  count: number;
  items: T[];
};

export type NfcUser = {
  title: string;
  description: string;
  company: string;
  birthday: number;
  displayOrder: number;
};

export type NfcChannel = {
  nfcChannelId?: number;
  channelTypeId: number;
  personalUrl: string;
  displayOrder: number;
};

export type NfcInfo = {
  nfcId: number;
  nfcKey: string;
  thumbAvatar: string;
  nfcUser: NfcUser;
  nfcChannels: NfcChannel[];
};

export type RealmType = {
  productRealmId: number;
  productRealmCode: string;
  productRealmName: string;
  displayOrder?: number;
  isHidden?: boolean;
};

export type ChannelTypeInfo = {
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  displayOrder: number;
  channelTypeId: number;
  channelName: string;
  icon: string;
  channelUrl: string;
};

export enum EnumAction {
  View,
  Delete,
  Edit,
  Add,
  Confirm,
  Cancel,
}

export enum EnumFilter {
  all = '',
  stocking = '1',
  outOfStock = '2',
}

export type EventButton = {
  name?: string;
  icon: string;
  actionType: EnumAction;
  action?: (...args: any[]) => any;
  buttonClass?: string;
  align: string;
  file?: boolean;
};


//Category
export type productCategoryAttributeType = {
  productCategoryAttributeId?: number;
  productCategoryId: number;
  attributeTitle: string;
  attributeName: string;
  attributeType: number;
  isShowProduct: number;
  isShowProductSerial: number;
  isShowProductSerialDetail: number;
};

export type CategoryType = {
  productCategoryId: number;
  productRealmId: number;
  productCategoryCode: string;
  productCategoryName: string;
  productRealm: RealmType;
  productCategoryAttributes?: productCategoryAttributeType[] | null;
  displayOrder?: number;
};

//Product
export type ProductType = {
  productId: number;
  productCategoryId: number;
  productCode: string;
  productName: string;
  price?: number;
  monthlySubscriptionFee?: number | null | undefined;
  phoneNumber?: string | null | undefined;
  specifications?: string | null | undefined;
  description?: string | null | undefined;
  cpu?: string | null | undefined;
  ram?: string | null | undefined;
  ssd?: string | null | undefined;
  hdd?: string | null | undefined;
  gpu?: string | null | undefined;
  display?: string | null | undefined;
  isSerial?: number;
  thumbAvatar?: string | null | undefined;
  avatar?: string | null | undefined;
  images?: ImageType[] | null | undefined;
  isStockRemain?: boolean;
  stockRemainQuantity?: number;
  introContent?: string; //Here
  discountPercent?: number;
  displayOrder?: number;
  isHiddenSerial?: boolean;
};

export type ImageType = {
  fileId: number;
  fileTypeId: number;
  fileName: string;
  fileUrl: string;
  thumbUrl: string;
};

//Product Serial
export type ProductSerialType = {
  productSerialId?: number;
  productId?: number;
  productCode?: string;
  productName?: string;
  serial?: string;
  price?: number;
  monthlySubscriptionFee?: number;
  phoneNumber?: string | null | undefined | number;
  specifications?: string | null | undefined;
  description?: string | null | undefined;
  cpu?: string;
  ram?: string;
  ssd?: string;
  hdd?: string;
  display?: string;
  gpu?: string;
  status?: number;
  thumbAvatar?: string;
  avatar?: string;
  images?: ImageType[];
  effectiveDate?: string;
  expirationDate?: string;
  displayOrder?: number;
};

//Company info
export type CompanyInfoType = {
  companyInfoId: number;
  companyInfoKey: string;
  companyInfoTitle: string;
  companyInfoValue: string;
  href: string;
};

export interface IDataBodyItem {
  keyword: string;
  page: number;
  size: number;
  title: string;
  listRealmIds?: number[];
  listCategoryIds?: number[];
}

//Static page
export type StaticPageType = {
  staticPageId: number;
  staticPageKey: string;
  staticPageTitle: string;
  staticPageValue: string;
  description: string;
};

export type ProductGiftType = {
  productGiftId?: number;
  productId: number;
  productGiftValue: string;
};

//Order requirement
export type OrderRequirementDetailsItemType = {
  orderRequirementDetailId?: number;
  productSerialId?: number;
  productSerial?: ProductSerialType;
};

export type OrderRequirementType = {
  orderRequirementId?: number;
  customerId?: number;
  customerFullname?: string;
  customerPhoneNumber?: string;
  customerAddress?: string;
  receiverFullname?: string;
  receiverPhoneNumber?: string;
  receiverAddress?: string;
  orderRequirementNote?: string;
  paymentMethod?: number;
  orderRequirementDetails?: OrderRequirementDetailsItemType[];
};

//News
export type NewsType = {
  displayOrder?: number;
  newId?: number;
  newAvatar?: string;
  newTitle?: string;
  newValue?: string;
  description?: string;
  commentLength?: number;
};

//Comment
export type CommentType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  commentId?: number;
  newId?: number;
  parentId?: number;
  commentValue?: string;
  commentLength?: number;
  avataUrl?: string;
  fullName?: string;
};

//Service
export type ServiceType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  serviceId?: number;
  serviceCode?: string;
  serviceName?: string;
  staticPageKey?: string;
};

//Tracking
export type TrackingStatusType = {
  trackingID?: string;
  statusName?: string;
  note?: string;
  date?: string;
};

export type TrackingDetailType = {
  trackingID?: string;
  statusID?: number;
  statusName?: string;
  status?: boolean;
};

export type TrackingType = {
  status?: number;
  message?: string;
  content?: {
    trackingDetail?: TrackingDetailType[];
    trackingStatus?: TrackingStatusType[];
  };
};

//SIM ORDER
export enum EnumSimOrderPaymentStatus {
  Waiting = 1,
  Paid = 2,
}

export enum EnumSimOrderProgressStatus {
  Waiting = 1,
  Sent = 2,
  Error = 3,
  RecallDefectiveGoods = 4,
}

export type SimOrderType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  fullname: string;
  phoneNumber: string;
  zipCode: string;
  address1?: string;
  address2: string;
  address3?: string;
  address4?: string;
  note?: string;
  progressStatus?: EnumSimOrderProgressStatus;
  paymentStatus?: EnumSimOrderPaymentStatus;
  simOrderDetails?: SimOrderDetailsType[];
  displayOrder?: number;
  simOrderId?: number;
  userId?: number;
  image?: string;
  thumbImage?: string;
  trackingCode?: string;
};

export type SimOrderDetailsType = {
  simOrderDetailId?: number;
  productId?: number;
  quantity?: number;
  product?: ProductType;
  simPhoneNumber?: number;
  paymentStatus?: EnumSimOrderPaymentStatus;
  price?: number;
  isCreatedSim?: boolean;
};

//Change password
export type ChangePasswordResult = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  userId?: number;
  birthday?: string;
  email: string;
  fullName?: string;
  groupId?: number;
  langId?: number;
  loginName?: string;
  password?: string;
  currentPassword?: string;
  confirmPassword?: string;
  telephone?: string | number;
  mainAddress?: string;
  facebookId?: string;
  googleId?: string;
  twitterId?: string;
  cmtnd?: null;
  cccd?: null;
  mobile?: null;
  avata?: null;
  avataUrl?: string;
  sex?: string;
  occupation?: string;
  fbAccessToken?: string;
  googleAccessToken?: string;
  cover?: string;
  loginNameHash?: string;
  refreshToken?: string;
};

//Sim
export enum EnumSimStatus {
  Actice = 1,
  Deactive = 2,
  Error = 3,
  StopService = 4,
}

export type SimType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simId?: number;
  userId?: number;
  userName?: string;
  customerName?: string;
  simPhoneNumber?: string;
  status?: EnumSimStatus;
  productId?: number;
  monthlySubscriptionFee?: number;
  effectiveDate?: string;
  product?: ProductType;
};

export enum EnumCustomerType {
  CTV = 1,
}

export type SimPriceType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simPriceId?: number;
  customerTypeId?: EnumCustomerType;
  productId?: number;
  price?: number;
  monthlySubscriptionFee?: number;
  effectiveDate?: string;
  product?: ProductType;
};

export type SimPriceDebitType = {
  totalMoney?: number;
  paidMoney?: number;
  remainUnpaidMoney?: number;
  simOrderDetails?: SimOrderDetailsType[];
  sendEmail?: boolean;
  paidOtherMoney?: number;
  totalOtherMoney?: number;
  remainUnpaidOtherMoney?: number;
};

//monthly-subscription-fee
export type MonthlySubscriptionFeeType = {
  totalMoney: number;
  sims: SimType[];
  sendEmail: boolean;
};

export type RolePermisionType = {
  roleId?: number;
  functionId?: number;
  permision?: number;
  actions?: {
    View?: boolean;
    Add?: boolean;
    Confirm?: boolean;
    Edit?: boolean;
    Delete?: boolean;
  };
};

export enum EnumDisplayConfig {
  Loai_san_pham = 2,
  Danh_muc_san_pham = 3,
  San_pham = 4,
  Dich_vu = 5,
  Banner = 6,
  Logo = 7,
  Thong_tin = 8,
  Hinh_anh_cong_ty = 9,
  Trang_thong_tin_tinh = 10,
  Danh_sach_dat_hang = 11,
  Tin_tuc = 12,
  Quan_ly_tai_khoan = 1,
  Cau_hinh_phan_quyen = 21,
  Phan_quyen = 22,
  Sim_order = 13,
  Sim_da_ban = 14,
  Lich_su_bang_gia = 23,
  Mau_email = 24,
  Cong_no = 25,
  ProductOrder = 26,
  ShipOrder = 27,
  PurchaseAccount = 28,
}

//Note
export type NoteType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  noteId?: number;
  userId?: number;
  functionId?: number;
  objectId?: number;
  noteContent?: string;
  avataUrl?: string;
  fullName?: string;
};

//Notification
export enum EnumNotificationStatus {
  Wait = 0,
  Seen = 1,
  Read = 2,
}

export enum EnumNotiTemplateKey {
  REPORT_SIM_ERROR = 'REPORT_SIM_ERROR',
  STOP_SERVICE = 'STOP_SERVICE',
  NOTE = 'NOTE',
  CREATE_PRODUCT_ORDER = 'CREATE_PRODUCT_ORDER',
  CREATE_SHIP_ORDER = 'CREATE_SHIP_ORDER',
  UPDATE_PRODUCT_ORDER = 'UPDATE_PRODUCT_ORDER',
  UPDATE_SHIP_ORDER = 'UPDATE_SHIP_ORDER',
  CREATE_ORDER_REQUIREMENT = 'CREATE_ORDER_REQUIREMENT',
  CHAT = 'CHAT',
}

export type NotificationType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  notificationId?: number;
  notificationTitle?: string;
  notificationValue?: string;
  receiverId?: number;
  notificationTemplateId?: number;
  notificationTemplateKey?: EnumNotiTemplateKey;
  status?: EnumNotificationStatus;
  fullName?: string;
  avataUrl?: string;
  notificationData?: string;
};

export type MyNotificationType = {
  data: PageData<NotificationType>;
  badge: number;
};

//Guide Page
export type GuidePageType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  guidePageId?: number;
  guidePageKey?: string;
  guidePageTitle?: string;
  guidePageValue?: string;
  description?: string;
  guideAvatar?: string;
};

//Sim news
export type SimNewsType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simNewId?: number;
  simNewAvatar?: string;
  simNewTitle?: string;
  simNewValue?: string;
  description?: string;
  commentLength?: number;
};

export type SimNewCommentType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simCommentId?: number;
  simNewId?: number;
  parentId?: number;
  simCommentValue?: string;
  commentLength?: number;
  avataUrl?: string;
  fullName?: string;
};

//Product Order
export enum EnumProductOrderProgressStatus {
  Waiting = 1,
  Bought = 2,
  JapanStore = 3,
}

export type ProductOrderType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  productOrderId?: number;
  userId?: number;
  userName?: string;
  productUrl?: string;
  productName?: string;
  productPrice?: number;
  productOrderFee?: number;
  description?: string;
  quantity?: number;
  note?: string;
  thumbProductImage?: string;
  productImage?: string;
  progressStatus?: EnumProductOrderProgressStatus;
  japanTrackingCode?: string;
  shipOrderId?: number;
};

export enum EnumShipOrderProgressStatus {
  Waiting = 0,
  JapanStore = 1,
  Flying = 2,
  ThroughCustoms = 3,
  VietNamStore = 4,
  Contact = 5,
  Finish = 6,
}


export enum EnumShipOrderProgressStatusTitle {
  Waiting = 'Đang chờ',
  JapanStore = 'Đến kho Nhật Bản',
  Flying = 'Đang bay',
  ThroughCustoms = 'Chờ thông quan',
  VietNamStore = 'Đến kho Việt Nam',
  Contact = 'Liên hệ khách hàng',
  Finish = 'Hoàn thành',
}


export const ShipOrderProgressStatusList = [
  {
    value: EnumShipOrderProgressStatus.Waiting,
    title: EnumShipOrderProgressStatusTitle.Waiting,
    css: { color: '#3a3131' },
  },
  {
    value: EnumShipOrderProgressStatus.JapanStore,
    title: EnumShipOrderProgressStatusTitle.JapanStore,
    css: { color: 'blue' },
  },
  {
    value: EnumShipOrderProgressStatus.Flying,
    title: EnumShipOrderProgressStatusTitle.Flying,
    css: { color: 'violet' },
  },
  {
    value: EnumShipOrderProgressStatus.ThroughCustoms,
    title: EnumShipOrderProgressStatusTitle.ThroughCustoms,
    css: { color: 'yellow' },
  },
  {
    value: EnumShipOrderProgressStatus.VietNamStore,
    title: EnumShipOrderProgressStatusTitle.VietNamStore,
    css: { color: 'darkorange' },
  },
  {
    value: EnumShipOrderProgressStatus.Contact,
    title: EnumShipOrderProgressStatusTitle.Contact,
    css: { color: 'red' },
  },
  {
    value: EnumShipOrderProgressStatus.Finish,
    title: EnumShipOrderProgressStatusTitle.Finish,
    css: { color: 'green' },
  },
];

export type SpecialShipType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  specialShipId?: number;
  specialShipName: string;
  unit: string;
}

export type SpecialSurchargeType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  specialSurchargeId?: number;
  specialShipId: number;
  specialSurcharge: number;
  minQuantity: number;
}

export enum EnumPaymentType {
  NormalWeight = 0,
  NormalVolume = 1,
  ConvertWeight = 2,
  OverWeight = 3,
}

export enum EnumTransportType {
  HCMAir = 0,
  HNAir = 1,
  HCMSea = 2,
  HNSea = 3,
}

export enum EnumTransportTypeTitle {
  HCMAir = "Đường bay HCM",
  HNAir = "Đường bay Hà Nội",
  HCMSea = "Đường biển HCM",
  HNSea = "Đường biển Hà Nội",
}



export const TransportType = [
  {
    value: EnumTransportType.HCMAir,
    title: EnumTransportTypeTitle.HCMAir,
    css: { color: 'blue' },
  },
  {
    value: EnumTransportType.HNAir,
    title: EnumTransportTypeTitle.HNAir,
    css: { color: '#3a3131' },
  },
  {
    value: EnumTransportType.HCMSea,
    title: EnumTransportTypeTitle.HCMSea,
    css: { color: 'yellow' },
  },
  {
    value: EnumTransportType.HNSea,
    title: EnumTransportTypeTitle.HNSea,
    css: { color: 'yellow' },
  }
];

export type ShipOrderType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  shipOrderId?: number;
  userId?: number;
  userName?: string;
  receiverOrderId?: number;
  receiverOrderName?: string;
  price?: number;
  japanTrackingCode?: string;
  vietnamTrackingCode?: string;
  trackingCode?: string;
  weight?: number;
  height?: number;
  length?: number;
  width?: number;
  description?: string;
  customerNote?: string;
  transportFee?: number;
  note?: string;
  progressStatus?: EnumShipOrderProgressStatus;
  productOrderIds?: number[];
  billCode?: string;
  senderName?: string;
  senderAddress?: string;
  receiverAddress?: string;
  isPaid?: boolean;
  specialProduct?: string;
  nomalProduct?: string;
  receiverPhoneNumber?: string;
  receiverName?: string;
  images?: ImageType[];
  transportType?: number;
  shipPackageId?: number;
  paymentType?: number;
};


export enum EnumSearchOption {
  yahoo_auction = 1,
  rakuten = 2,
  amazon = 3,
  mercari = 4,
  yahoo_shopping = 5,
  paypay = 6,
  custom = 7,
}

//Address
export type AddressResultType = {
  message?: string;
  status?: number;
  results?: [
    {
      address1?: string;
      address2?: string;
      address3?: string;
      kana1?: string;
      kana2?: string;
      kana3?: string;
      prefcode?: string;
      zipcode?: string;
    },
  ];
};

//Receiver info
export type ReceiverInfoType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  receiverInfoId?: number;
  userId?: number;
  fullname?: string;
  phoneNumber?: string;
  zipCode?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  facebook?: string;
  zalo?: string;
};

//Account balance
export type AccountBalanceType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  accountHistoryId?: number;
  userId?: number;
  accountActionType?: number;
  moneyAmount?: number;
  note?: string;
  accountActionTitle?: string;
  coefficient?: number;
  data?: string;
};

export type AccountBalanceMoneyType = {
  userId: number;
  moneyAmount: number;
  note?: string;
};

//PurchaseAccount
export type PurchaseAccountType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  purchaseAccountId?: number;
  userId?: number;
  purchasePage?: string;
  accountName?: string;
  accountPassword?: string;
  description?: string;
  note?: string;
};

export type FirebaseTokenType = {
  firebaseTokenId: number;
  userName: string;
  deviceId: string;
  firebaseToken: string;
};


export type RoomType = {
  roomId: string;
  roomName: string;
  roomType: number;
  roomUsers: RoomUserType[];
  isCollapse?: boolean;
};

export type RoomUserType = {
  roomId: string;
  userId: number;
  fullName: string;
  avatarUrl: string;
  status: number;
};


export type MessageType = {
  messageId: number;
  roomId: string;
  userId: number;
  messageValue: string;
  messageType: number;
};

export enum EnumChatStatus {
  Waiting = 0,
  Sent = 1,
}

export type ReceiverOrderType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  receiverOrderId?: number;
  receiverOrderCode?: string;
  title?: string;
};

export type PaymentType = {
  paymentId?: number;
  shipOrder?: string;
  bill?: number[];
  money?: number;
  status?: number;
  expirationDate?: string;
  paymentCode?: string;
  customerName?: string;
  paymentContent?: string;
  customerEmail?: string;
};
