import React from 'react';
import Images from 'src/assets/img';
import './WhyChooseJVS.css';

const WhyChooseJVS: React.FC = () => {
  //Main
  return (
    <div className="container-fluid mt-lg-5 mt-3">
      <div className="row justify-content-center p-0 choose-jvs_container">
        <div className="col-lg-5 col-11 m-0 choose-jvs_component mt-3 p-4">
          <div className="choose-jvs_child">
            Không tính cân thể tích, giá cạnh tranh, minh bạch
          </div>
          <div className="choose-jvs_child">
            Bảo hiểm 100% giá trị hàng hoá, hàng về nhanh chóng
          </div>
          <div className="choose-jvs_child">
            Nhân viên trình độ tiếng Nhật business level, hỗ trợ 24/7
          </div>
          <div className="choose-jvs_child">
            Ưu đãi theo doanh thu, hỗ trợ chế độ ưu đãi cộng tác viên
          </div>
        </div>
        <div className="col-lg-3 col-11 m-0 choose-jvs_component mt-3">
          <img src={Images.made_in_jp} alt="made-in-japan" />
        </div>
      </div>
    </div>
  );
};

export default WhyChooseJVS;
