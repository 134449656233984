import axios, { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import { useConfiguration } from 'src/contexts/ConfigProvider/ConfigProvider';
import useProfile from 'src/hooks/useProfile';
import { useRemoveProfileInfo, useSetProfileInfo } from 'src/state/application/hooks';

//Translate
const instance = axios.create({});

const useTranslateFetch = () => {
  const { translateUrl } = useConfiguration();
  const profile = useProfile();
  const removeProfileInfo = useRemoveProfileInfo();
  const setProfileInfo = useSetProfileInfo();

  const fetch = useCallback(
    async <T = any>(config: AxiosRequestConfig) => {
      const renewToken = async () => {
        const config: AxiosRequestConfig = {
          baseURL: translateUrl,
          responseType: 'json',
          params: {},
          headers: {
            'Content-Type': 'application/json;',
          },
          url: 'scidm/rest-api/security/renew-token',
          method: 'post',
          data: {
            token: profile?.accessToken,
            refreshToken: profile?.refreshToken,
          },
        };
        try {
          const res = await instance.request(config);
          return res.status == 200 ? res.data : undefined;
        } catch (e) {
          return undefined;
        }
      };

      const request = async (config: AxiosRequestConfig) => {
        return instance
          .request(config)
          .then((res) => res.data)
          .catch(async (e) => {
            if (e.response && e.response.status == 401) {
              const newToken = await renewToken();
              if (newToken) {
                setProfileInfo({
                  accessToken: newToken.token,
                  refreshToken: newToken.refreshToken,
                  info: profile?.info,
                });
                config.headers['Authorization'] = `Bearer ${newToken.token}`;
                const res = (await request(config)) as Promise<any>;
                return res;
              } else {
                removeProfileInfo();
                return e.response && e.response.data
                  ? Promise.reject(e.response.data)
                  : Promise.reject(e.response || new Error('General error'));
              }
            } else {
              return e.response && e.response.data
                ? Promise.reject(e.response.data)
                : Promise.reject(e.response || new Error('General error'));
            }
          });
      };

      if (!translateUrl) {
        return;
      }
      config.baseURL = translateUrl;
      config.responseType = 'json';
      config.params = {
        ...(config.params || {}),
      };
      if (!config.headers) {
        config.headers = {
          'Content-Type': 'application/json;',
        };
      }

      return request(config) as Promise<T>;
    },
    [
      translateUrl,
      profile?.accessToken,
      profile?.refreshToken,
      profile?.info,
      setProfileInfo,
      removeProfileInfo,
    ],
  );

  return fetch;
};

export const useGetTranslateToJp = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useTranslateFetch();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (q: string) => {
      return fetch({
        url: `single?client=gtx&sl=auto&tl=ja&dt=t&q=${q}`,
        method: 'get',
      }) as Promise<any>;
    },
    [fetch],
  );
};
