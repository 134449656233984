import React, { useEffect, useState } from 'react';
import './HeaderBar.css';
import ButtonNavLink from 'src/components/ButtonNavLink/ButtonNavLink';
import useProfile from 'src/hooks/useProfile';
import { useRemoveProfileInfo } from 'src/state/application/hooks';
// import { getFCMToken } from 'src/firebase';
import { useDeleteFirebaseToken } from 'src/api/firebaseTokenApi';
import ChatIcon from 'src/components/ChatIcon/ChatIcon';
import ContactIcon from 'src/components/ContactIcon/ContactIcon';
// import FCMNotificationIcon from 'src/components/FCMNotificationIcon/FCMNotificationIcon';
import styled from 'styled-components';
// import { isSupported } from 'src/utils/notificationUtils';
import { useGetExchangeRate } from 'src/api/commonInfomationApi';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

const HeaderBar: React.FC = () => {
  //Value
  const profile = useProfile();
  const [exchangeRate, setExchangeRate] = useState(0);
  const [isHidden, setHidden] = useState(false);
  const location = useLocation();
  //Function
  const removeProfileInfo = useRemoveProfileInfo();
  const deleteFirebaseToken = useDeleteFirebaseToken();
  const getExchangeRate = useGetExchangeRate();
  const navigate = useNavigate();

  useEffect(() => {
    setHidden(location.pathname.includes("/auth") || location.pathname.includes("payment/"));
  }, [location]);

  useEffect(() => {
    getExchangeRate()
      .then((r) => {
        setExchangeRate(Math.ceil(r));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getExchangeRate]);

  const logout = () => {
    removeProfileInfo();
    // if(isSupported())
    //   getFCMToken().then((fcmToken: string) => {
    //     if (fcmToken) {
    //       deleteFirebaseToken(fcmToken).then(() => {
    //         console.log('delete token ' + fcmToken);
    //       });
    //     }
    //   });
  };

  //Main
  return (

    !isHidden && <HeaderBarContainer>
      <div className="d-flex base-title">1¥ = {exchangeRate} VNĐ</div>
      {profile ? (<FlexRowWrapper>
        {/* <ChatIcon />
        <ContactIcon /> */}
        {/* <FCMNotificationIcon /> */}
        <ButtonNavLink
          link={'/auth'}
          activeClass={'focus'}
          iconName={'logout'}
          name={'Đăng xuất'}
          backgroundColor={'red'}
          color={'black'}
          buttonClass={'info'}
          onClick={logout}
        />
      </FlexRowWrapper> ) : ( <>
        <div className="header-connection">
          <div
            className="base-head-icon d-none d-lg-flex"
            title="Facebook"
            onClick={() => {
              const url = 'guide-page';
              navigate(url);
              window.scrollTo(0, 0);
            }}
          >
            <i className="fab fa-facebook"></i>
          </div>
          <div
            className="base-head-icon d-none d-lg-flex"
            title="Youtube"
            onClick={() => {
              const url = 'sim-news';
              navigate(url);
              window.scrollTo(0, 0);
            }}
          >
            <i className="fab fa-youtube"></i>
          </div>
          <div
            className="base-head-icon d-none d-lg-flex"
            title="Tiktok"
            onClick={() => {
              const url = 'sim-news';
              navigate(url);
              window.scrollTo(0, 0);
            }}
          >
            <i className="fab fa-tiktok"></i>
          </div>
        </div>
        <div className="header-auth-button">
          <NavLink to={'/auth'} className='login-button'>
            <div className="header_no-login_btn">ĐĂNG NHẬP</div>
          </NavLink>
          <a href='/auth/register' >Tạo tài khoản</a>
        </div>
      </>)}

    </HeaderBarContainer>
  );
};

export default HeaderBar;

const HeaderBarContainer = styled.div`
  padding: 5px 10px;
  font-weight: 700;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 5px 10px lightgray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const FlexRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: end;
`;
