import React, { useEffect, useState } from 'react';
import './ButtonComponent.css';
import Loading from '../Loading/Loading';
import styled from 'styled-components';

interface IButtonComponent {
  width?: string;
  height?: string;
  icon?: string;
  title?: string;
  onClick?: (...args: any[]) => any | Promise<any>;
  loader?: boolean;
  className?: string;
}

const ButtonComponent: React.FC<IButtonComponent> = (props) => {
  const [isLoading, setLoading] = useState(false);
  const {width, height, icon, title, onClick, loader, className} = props;

  const click = () => {
    if(isLoading) return;
    if(onClick) {
      if(loader) {
        setLoading(true);
        onClick().then(() => {
          setLoading(false);
        }).catch(() => {
          console.log("errr")
          setLoading(false);
        });
      } else {
        onClick();
      }
    }
  }

  return (
    <ButtonStyled className={className} onClick={click} width={width} height={height}>
      {icon && !isLoading ? <ButtonIconStyled className="material-icons button-component-content">{icon}</ButtonIconStyled> : null}
      {isLoading && <ButtonLoadingStyled><Loading/></ButtonLoadingStyled>}
      <ButtonTitleStyled>{title}</ButtonTitleStyled>

    </ButtonStyled>
  );
};

export default ButtonComponent;

const ButtonStyled = styled.button<{ width?: string, height?: string}>`
  background-color: #256cb8;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  padding: 5px 10px;
  width: ${({width}) => width? width: 'fit-content'};
  height: ${({height}) => height? height: 'fit-content'};
  display: flex;
  flex-direction: row;
  cursor: pointer;
  &:hover {
    background-color: gray;
    color: white;
  }
  &:focus {
    border: none;
    outline: none;
  }
  &:active {
    transform: translateY(1px);
  }
`;

const ButtonIconStyled = styled.span`
  vertical-align: middle;
  margin: auto 5px;
`;

const ButtonTitleStyled = styled.label`
  vertical-align: middle;
  margin: auto 5px;
`;

const ButtonLoadingStyled = styled.div`
  vertical-align: middle;
  margin: 5px 10px 5px 0;
`;
