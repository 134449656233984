import React, { useCallback, useEffect, useState } from 'react';
import Images from 'src/assets/img';
import TrackingComponent from 'src/components/TrackingComponent/TrackingComponent';
import useQuery from 'src/hooks/useQuery';
import { useAddPopup } from 'src/state/application/hooks';
import './Tracking.css';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Input from 'src/components/Input';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import useModal from 'src/hooks/useModal';
import QRCodeReader from 'src/components/QRCodeReader/QRCodeReader';

const Tracking: React.FC = () => {
  const query = useQuery();
  const [code, setCode] = useState<string>();
  const [trackingCodes, setTrackingCodes] = useState<string[]>();
  const qrReaderModal = useModal(QRCodeReader);
  useEffect(() => {
    setCode(query.get('trackingCode'));
  }, [query])


  //Function
  const addPopup = useAddPopup();

  const onTracking = useCallback(() => {
    if (code && code.trim().length > 0) {
      setTrackingCodes(code.split(',').map(c => c.trim()));
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Bạn chưa nhập Tracking Id',
        },
      });
    }
  }, [addPopup, code]);
  //End of function

  useEffect(() => {
    const enterFunction = (event: any) => {
      if (event.keyCode === 13) {
        onTracking();
      }
    };
    document.addEventListener('keydown', enterFunction, false);
    return () => {
      document.removeEventListener('keydown', enterFunction, false);
    };
  }, [onTracking]);

  return (

    <>
      <ToolBar
        toolbarName='TRA CỨU ĐƠN VẬN CHUYỂN'
        width={'100%'}
        listRightButton={[]}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      />
      <div className='tracking-container'>
        <div  className='tracking-row'>
          Vui lòng nhập mã đơn vận chuyển hoặc mã vận chuyển JP (phân tách nhau bằng dấu , )
        </div>
        <div className='tracking-row'>
          <Input
            width='76vw'
            require={true}
            disabled={false}
            value={code}
            title='Mã tra cứu'
            placeholder='VD: 00000001,00000002,00000003'
            showAllTilteAndPlaceholder={true}
            onChange={setCode}
          />
          <div className="ship-order-scanner-qr">
            <span className="material-icons" onClick={() => {
              qrReaderModal.handlePresent({
                readQR: (value: string) => {
                  setCode(code.trim().length > 0? `${code.trim()},${value}` : value);
                  qrReaderModal.handleDismiss();
                }
              }, "QUÉT MÃ VẬN CHUYỂN")
            }}>qr_code</span>
          </div>
        </div>
        <div className='tracking-row'>
          <ButtonComponent title='TRA CỨU' onClick={onTracking}/>
        </div>
        <div className='tracking-data'>
          {trackingCodes?.map(trackingCode => <TrackingComponent trackingCode={trackingCode} />)}
        </div>
      </div>
    </>

  );
};

export default Tracking;
