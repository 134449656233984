
import { useCallback } from 'react';
import { useFetch } from './backend-api';
import {
  PageData,
  PaymentType,
} from './models';

/***********************************/
export const useGetMyPayments = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number,) => {
      return fetch({
        url: `sccore/rest-api/payment/me?page=${page??0}&size=${size??0}&keyword=${keyword??''}`,
        method: 'get',
      }) as Promise<PageData<PaymentType>>;
    },
    [fetch],
  );
};


export const useGetPaymentByCode = () => {
  const fetch = useFetch();
  return useCallback(
    (paymentCode: string) => {
      return fetch({
        url: `sccore/rest-api/payment/code/${paymentCode}`,
        method: 'get',
      }) as Promise<PaymentType>;
    },
    [fetch],
  );
};
