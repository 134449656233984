import React from 'react';
import { PurchaseAccountType } from 'src/api/models';
import './PurchaseAccountComponent.css';
import { useNavigate } from 'react-router-dom';

interface IPurchaseAccountComponent {
  data: PurchaseAccountType;
  index: number;
}

const PurchaseAccountComponent: React.FC<IPurchaseAccountComponent> = (props) => {
  //Value
  const data = props.data;
  const navigate = useNavigate();

  //Main
  return (
    <div
      className={`col-lg-11 col-12 purchase-account_component_container ${
        props.index % 2 == 0 ? 'even' : ''
      }`}
    >
      <div className="purchase-account-wrapper">
        <div className="purchase-account_component_child">Loại TK: {data.purchasePage}</div>
        <div className="purchase-account_component_child">
          Tên đăng nhập: {data.accountName}
        </div>
        <div className="purchase-account_component_child">Mật khẩu: {data.accountPassword}</div>
        <div className="purchase-account_component_child">Mô tả: {data.description}</div>
        <div className="purchase-account_component_child">Ghi chú: {data.note}</div>
      </div>
      <div className="choose-purchase-account_edit-btn">
        <button
          className="dashboard_button_child purchase-account_cpn_btn"
          onClick={() => {
            navigate(`/account/purchase-account/edit?tid=${data.purchaseAccountId}`);
          }}
        >
          Báo lỗi
        </button>
      </div>
    </div>
  );
};

export default PurchaseAccountComponent;
