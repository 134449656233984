import React, { useState, useEffect } from 'react';
import { ReceiverInfoType } from 'src/api/models';
import { useGetMyReceiverInfo, usePostReceiverInfo } from 'src/api/receiverInfoApi';
import ReceiverInfoComponent from 'src/components/ReceiverInfoComponent/ReceiverInfoComponent';
import useProfile from 'src/hooks/useProfile';
import { useAddPopup } from 'src/state/application/hooks';
import './AccountAddressCommon.css';

const AccountAddressCommon: React.FC = () => {
  //Value
  const profile = useProfile();

  //State
  const [receiverInfoList, setReceiverInfoList] = useState<ReceiverInfoType[]>([]);

  const [fullname, setFullname] = useState('');
  const [fullnameError, setFullnameError] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(null);

  const [zipCode, setZipCode] = useState('');

  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [address4, setAddress4] = useState('');
  const [addressError, setAddressError] = useState(null);

  const [facebook, setFacebook] = useState('');

  const [zalo, setZalo] = useState('');

  const [focusInput, setFocusInput] = useState(null);
  const [reloadFlag, setReloadFlag] = useState(false);
  //End of state

  //Function
  const addPopup = useAddPopup();
  const postReceiverInfo = usePostReceiverInfo();
  const getMyReceiverInfo = useGetMyReceiverInfo();

  const onAddNewReceiverInfo = () => {
    const isFullName = validateFullName();
    const isPhoneNumber = validatePhoneNumber();
    const isAddress = validateAddress();
    if (isFullName && isPhoneNumber && isAddress) {
      const receiverInfo: ReceiverInfoType = {
        fullname: fullname,
        phoneNumber: phoneNumber,
        zipCode: zipCode,
        address1: address1,
        address2: address2,
        address3: address3,
        address4: address4,
        facebook: facebook,
        zalo: zalo,
      };
      postReceiverInfo(receiverInfo)
        .then(() => {
          setReloadFlag(!reloadFlag);
          setFullname('');
          setPhoneNumber('');
          setZipCode('');
          setAddress1('');
          setAddress2('');
          setAddress3('');
          setAddress4('');
          setFacebook('');
          setZalo('');
          addPopup({
            txn: {
              success: true,
              summary: 'Thêm địa chỉ thành công',
            },
          });
        })
        .catch((error) => {
          setReloadFlag(!reloadFlag);
          console.log(error);
        });
    }
  };

  //Validate
  const validateFullName = () => {
    let isContinue = true;

    if (!fullname || fullname == '') {
      isContinue = false;
      setFullnameError('Chưa nhập tên người nhận');
    } else setFullnameError(null);

    return isContinue;
  };

  const validatePhoneNumber = () => {
    let isContinue = true;

    if (!phoneNumber || phoneNumber == '') {
      isContinue = false;
      setPhoneNumberError('Chưa nhập số điện thoại');
    } else setPhoneNumberError(null);

    return isContinue;
  };

  const validateAddress = () => {
    let isContinue = true;

    if (
      (!address1 || address1 == '') &&
      (!address2 || address2 == '') &&
      (!address3 || address3 == '')
    ) {
      isContinue = false;
      setAddressError('Chưa địa chỉ');
    } else setAddressError(null);

    return isContinue;
  };
  //End of validate

  //Component
  const receiverInfoListComponent = () => {
    return (
      <div className="row justify-content-center p-2 mt-2 mr-2 ml-2">
        {receiverInfoList.map((value, index) => {
          return <ReceiverInfoComponent data={value} index={index} />;
        })}
      </div>
    );
  };

  //1
  const fullNameInput = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 1 ? 'focus-input' : ''} ${
            fullname ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Tên người nhận</div>
          <input
            type="text"
            value={fullname}
            onChange={(event) => {
              setFullname(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(1);
            }}
            onBlur={() => {
              validateFullName();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 1}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{fullnameError}</div>
      </div>
    );
  };

  //2
  const phoneNumberInput = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 2 ? 'focus-input' : ''} ${
            phoneNumber ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Số điện thoại</div>
          <input
            type="text"
            value={phoneNumber}
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(2);
            }}
            onBlur={() => {
              validatePhoneNumber();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 2}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{phoneNumberError}</div>
      </div>
    );
  };

  //3
  const zipCodeInput = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 3 ? 'focus-input' : ''} ${
            zipCode ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Mã bưu điện</div>
          <input
            type="text"
            value={zipCode}
            onChange={(event) => {
              setZipCode(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(3);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            placeholder={'Chỉ dùng ở Nhật Bản'}
            autoFocus={focusInput == 3}
          />
        </div>
      </div>
    );
  };

  //4
  const address1Input = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 4 ? 'focus-input' : ''} ${
            address1 ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Tỉnh, thành phố</div>
          <input
            type="text"
            value={address1}
            onChange={(event) => {
              setAddress1(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(4);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 4}
          />
        </div>
      </div>
    );
  };

  //5
  const address2Input = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 5 ? 'focus-input' : ''} ${
            address2 ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Quận, huyện</div>
          <input
            type="text"
            value={address2}
            onChange={(event) => {
              setAddress2(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(5);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 5}
          />
        </div>
      </div>
    );
  };

  //6
  const address3Input = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 6 ? 'focus-input' : ''} ${
            address3 ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Xã, phường, thị trấn</div>
          <input
            type="text"
            value={address3}
            onChange={(event) => {
              setAddress3(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(6);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 6}
          />
        </div>
      </div>
    );
  };

  //7
  const address4Input = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 7 ? 'focus-input' : ''} ${
            address4 ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Số nhà, tên đường</div>
          <input
            type="text"
            value={address4}
            onChange={(event) => {
              setAddress4(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(7);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 7}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{addressError}</div>
      </div>
    );
  };

  //8
  const facebookInput = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 8 ? 'focus-input' : ''} ${
            facebook ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Link Fb</div>
          <input
            type="text"
            value={facebook}
            onChange={(event) => {
              setFacebook(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(8);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 8}
          />
        </div>
      </div>
    );
  };

  //9
  const zaloInput = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 9 ? 'focus-input' : ''} ${
            zalo ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Zalo</div>
          <input
            type="text"
            value={zalo}
            onChange={(event) => {
              setZalo(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(9);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 9}
          />
        </div>
      </div>
    );
  };

  const addComponent = () => {
    return (
      <div className={`row justify-content-center p-2 m-2 address-wrapper `}>
        <div className="accordion col-lg-11 col-12 m-0 p-0" id="myaccordion">
          <div className="m-0">
            <button
              className="dashboard_button_child address"
              data-toggle="collapse"
              data-target="#noidungcard1"
              aria-expanded="true"
              data-parent="#myaccordion"
            >
              Thêm địa chỉ
            </button>
            <div
              className="card-body collapse address-component_child mt-2"
              data-toggle="collapse"
              aria-expanded="false"
              id="noidungcard1"
            >
              {fullNameInput()}
              {phoneNumberInput()}
              {zipCodeInput()}
              {address1Input()}
              {address2Input()}
              {address3Input()}
              {address4Input()}
              {facebookInput()}
              {zaloInput()}
              <div className="add-address_btn_container">
                <button
                  className="dashboard_button_child address-add"
                  onClick={() => {
                    onAddNewReceiverInfo();
                  }}
                >
                  Thêm địa chỉ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //End of component

  //useEffect
  useEffect(() => {
    getMyReceiverInfo()
      .then((data) => {
        setReceiverInfoList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getMyReceiverInfo, reloadFlag]);

  //Main
  return (
    <div className="container-fluid dashboard-container p-0">
      <div className="dashboard_title">Địa chỉ nhận hàng</div>
      {addComponent()}
      {receiverInfoListComponent()}
    </div>
  );
};

export default AccountAddressCommon;
