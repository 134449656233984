import React, { useState } from 'react';
import './QRCodeReader.css';
import { QrReader } from 'react-qr-reader';

interface IQRCodeReader {
  readQR?: (...arg: any[]) => void
}

const QRCodeReader: React.FC<IQRCodeReader> = (props) => {

  const USER = "user";
  const ENVIROMENT = "environment";

  const [facingMode, setFacingMode] = useState<"environment" | "user">(ENVIROMENT);

  return (
    <div className="qrcode-reader-content">
      <QrReader
        onResult={(result: any) => {
          if (result) {
            if(props.readQR) props.readQR(result?.text);
          }
        }}
        constraints={{facingMode: facingMode}}
        containerStyle={{
          width: '400px',
          height: '400px',
          maxWidth: '80vw',
          maxHeight: '80vh'
        }}
      />
      <div className="switch-camera" onClick={() => setFacingMode(facingMode == USER? ENVIROMENT : USER)}>
        <span className="material-icons">sync</span>
      </div>
    </div>
  );
};

export default QRCodeReader;
