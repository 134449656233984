import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StaticPageType } from 'src/api/models';
import NavComponent from 'src/components/NavComponent/NavComponent';
import './StaticPage.css';
import { useGetStaticPageByKey } from 'src/api/staticPageApi';

const StaticPage: React.FC = () => {
  //Value
  const params = useParams<{ key: string }>();
  const key = params.key;

  //State
  const [staticPage, setStaticPage] = useState<StaticPageType>(null);

  //End of state

  //Function
  const getStaticPageByKey = useGetStaticPageByKey();

  //useEffect
  useEffect(() => {
    getStaticPageByKey(key)
      .then((_staticPage) => {
        if (_staticPage) {
          setStaticPage(_staticPage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getStaticPageByKey, key]);

  //Main
  return (
    <div className="container-fluid dashboard-container p-0 mb-0">
      <div className="container-fluid dashboard-container mb-0">
        <div className="dashboard_title">
          {staticPage ? staticPage.staticPageTitle : '404 NOT FOUND'}
        </div>
        {staticPage ? (
          <div
            className="mr-0 ml-0 mt-3 mb-0"
            dangerouslySetInnerHTML={{
              __html: staticPage.staticPageValue,
            }}
          />
        ) : (
          <div className="error" id="error-page">
            <div className="container-floud">
              <div className="col-xs-12 ground-color text-center">
                <div className="container-error-404">
                  <div className="clip">
                    <div className="shadow">
                      <span className="digit showtotop delay02 thirdDigit">4</span>
                    </div>
                  </div>
                  <div className="clip">
                    <div className="shadow">
                      <span className="digit showtotop delay04 secondDigit">0</span>
                    </div>
                  </div>
                  <div className="clip">
                    <div className="shadow">
                      <span className="digit showtotop delay06 firstDigit">4</span>
                    </div>
                  </div>
                  <div className="msg">
                    OH!<span className="triangle"></span>
                  </div>
                </div>
                <h2 className="h1">Sorry! Page not found</h2>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StaticPage;
