/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useCallback } from 'react';
import { EnumAction, EnumDataType, EnumDisplayConfig, EnumPaymentType, EnumShipOrderProgressStatus, EnumTransportType, ImageType, ProductOrderType, ReceiverInfoType, ReceiverOrderType, ShipOrderProgressStatusList, ShipOrderType, SpecialShipType, SpecialSurchargeType, TransportType } from 'src/api/models';
import { useDeleteShipOrderImage, useGetShipOrderById, usePostMyShipOrder, usePostShipOrderImage, usePutMyShipOrder } from 'src/api/shipOrderApi';
import Avatar from 'src/components/Avatar';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Input from 'src/components/Input';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import SelectSearchComponent from 'src/components/SelectSearchComponent/SelectSearchComponent';
import useProfile from 'src/hooks/useProfile';
import { useAddPopup } from 'src/state/application/hooks';
import "./ShipOrderDetail.css";
import { useGetReceiverOrder, useGetReceiverOrderByCode, useGetReceiverOrderById } from 'src/api/receiverOrderApi';
import { useNavigate, useParams } from 'react-router-dom';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Note from 'src/components/Note/Note';
import { useGetMyProductOrderByIds } from 'src/api/productOrdrerApi';
import Table from 'src/components/Table/Table';
import ListView from 'src/components/ListView/ListView';
import { ItemDisplayType } from 'src/components/ListView/ItemView/ItemView';
import ImageUpload from 'src/components/ImageUpload';
import { useGetMyReceiverInfo } from 'src/api/receiverInfoApi';
import useModal from 'src/hooks/useModal';
import QRCodeReader from 'src/components/QRCodeReader/QRCodeReader';

interface IShipOrderDetail {
  shipOrderId?: number;
  isDisable?: boolean;
  isPopup?: boolean;
  postProcess?: (...args: any[]) => void;
}

const ShipOrderDetail: React.FC<IShipOrderDetail> = (props) => {

  const [shipOrderId, setShipOrderId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);
  const params = useParams<{type: string; shipOrderId: string }>();
  const profile = useProfile();
  useEffect(() => {
    setShipOrderId(props.shipOrderId || Number(params.shipOrderId));
    setIsDisable(Number(params.type) == EnumViewType.View || props.isDisable);
  }, [params.shipOrderId, params.type, props.isDisable, props.shipOrderId]);
  const navigate = props.isPopup ? undefined : useNavigate();

  const [userId, setUserId] = useState<number>();
  const [userName, setUserName] = useState<string>();
  const [billCode, setBillCode] = useState<string>();
  const [weight, setWeight] = useState<number>();
  const [quantity, setQuantity] = useState<number>();
  const [transportFee, setTransportFee] = useState<number>();
  const [progressStatus, setProgressStatus] = useState<EnumShipOrderProgressStatus>(EnumShipOrderProgressStatus.Waiting);
  const [japanTrackingCode, setJapanTrackingCode] = useState<string>();
  const [vietnamTrackingCode, setVietnamTrackingCode] = useState<string>();
  const [price, setPrice] = useState<number>();
  const [description, setDescription] = useState<string>();
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState<string>();
  const [receiverName, setReceiverName] = useState<string>();
  const [senderAddress, setSenderAddress] = useState<string>();
  const [receiverAddress, setReceiverAddress] = useState<string>();
  const [specialProduct, setSpecialProduct] = useState<string>();
  const [nomalProduct, setNomalProduct] = useState<string>();
  const [customerNote, setCustomerNote] = useState<string>();
  const [productOrderIds, setProductOrderIds] = useState<number[]>([]);
  const [productOrders, setProductOrders] = useState<ProductOrderType[]>([]);
  const [transportType, setTransportType] = useState<EnumTransportType>();
  const [paymentType, setPaymentType] = useState<number>();
  const [senderName, setSenderName] = useState<string>();
  const [isPaid, setPaid] = useState<boolean>();
  const [length, setLength] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [receiverOrderId, setReceiverOrderId] = useState<number>();
  const [images, setImages] = useState<ImageType[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [receiverInfos, setReceiverInfos] = useState<ReceiverInfoType[]>([]);
  const [suggessAddress, setSuggessAddress] = useState<any>();
  //End of state
  const qrReaderModal = useModal(QRCodeReader);

  //Function
  const addPopup = useAddPopup();
  const getShipOrder = useGetShipOrderById();
  const postShipOrder = usePostMyShipOrder();
  const putShipOrder =  usePutMyShipOrder();
  const postShipOderImage = usePostShipOrderImage();
  const deleteShipOrderImage = useDeleteShipOrderImage();
  const getMyProductOrderByIds = useGetMyProductOrderByIds();
  const getMyReceiverInfo = useGetMyReceiverInfo();

  const display: ItemDisplayType<ProductOrderType> = {
    header: [
      {
        code: 'productName',
        dataType: EnumDataType.Text,
        isOptions: false,
        cellCss: {
          display: 'flex',
          flex: 'auto'
        }
      }
    ],
    detail: {
      avatar: 'thumbProductImage',
      info: [
        [
          {
            code: 'description',
            dataType: EnumDataType.Text,
            isOptions: false,
          }
        ],
        [
          {
            code: 'quantity',
            dataType: EnumDataType.BigInt,
            isOptions: false,
            icon: 'shopping_cart',
            cellCss: {
              margin: '0 5px'
            }
          },
          {
            code: 'productPrice',
            dataType: EnumDataType.JPY,
            isOptions: false,
            icon: 'payments',
            cellCss: {
              margin: '0 5px'
            }
          }
        ]
      ]
    },
  };

  const tableDisplay = {
    header: [
      {
        code: 'thumbProductImage',
        dataType: EnumDataType.Image,
        isOptions: false,
        title: 'Ảnh',
      },
      {
        code: 'productName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên SP',
      },
      {
        code: 'productPrice',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Giá SP',
      },
      {
        code: 'quantity',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Số lượng',
      },
      {
        code: 'description',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mô tả hàng hóa',
      }
    ]
  };

  const onSuccess = (isAdd: boolean, res: ShipOrderType) => {
    addPopup({
      txn: {
        success: true,
        summary: isAdd? 'Tạo đơn vận chuyển thành công' : 'Thay đổi đơn vận chuyển thành công',
      }
    });
    if(props.postProcess) props.postProcess(res);
  }

  const onChangeEditMode = (e: MouseEvent) => {
    navigate(`/ship-order/${shipOrderId}/${EnumViewType.Edit}`);
  };

  const onSave = () => new Promise((resolve) => {

    if (description?.trim().length > 0 || japanTrackingCode?.trim().length > 0) {
      const shipOrder: ShipOrderType = {
        shipOrderId: shipOrderId,
        billCode: billCode,
        userId: userId,
        receiverOrderId: receiverOrderId,
        weight: weight,
        japanTrackingCode: japanTrackingCode,
        description: description,
        customerNote: customerNote,
        transportFee: transportFee,
        isPaid: isPaid,
        nomalProduct: JSON.stringify(nomalProduct),
        specialProduct: JSON.stringify(specialProduct),
        senderAddress: senderAddress,
        receiverAddress: receiverAddress,
        receiverName: receiverName,
        receiverPhoneNumber: receiverPhoneNumber,
        progressStatus: progressStatus,
        price: price,
        transportType: transportType,
        length: length,
        width: width,
        height: height,
        senderName: senderName,
        paymentType: paymentType,
        vietnamTrackingCode: vietnamTrackingCode
      };
      const isAdd = !shipOrderId;
      const api = isAdd? postShipOrder(shipOrder) : putShipOrder(shipOrder);
      api.then(
        (res) => {
          setShipOrderId(res.shipOrderId);
          setIsDisable(true);
          if (isAdd) {
            const uploadApis = imageFiles.map((image) => uploadImage(res.shipOrderId, image));
            Promise.all(uploadApis)
              .then((imageRes) => {
                setImages([...images, ...imageRes]);
                onSuccess(isAdd, res);
                resolve(true);
              })
              .catch((error) => {
                addPopup({
                  error: {
                    message: error.errorMessage,
                    title: 'Tải ảnh thất bại, vui lòng thử lại!',
                  },
                });
                resolve(false);
              });
            setImageFiles([]);
          } else {
            onSuccess(isAdd, res);
            resolve(true);
          }
        })
        .catch((error) => {
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
          resolve(false);
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Yêu cầu nhập mô tả hoặc có mã vận chuyển JP',
        },
      });
      resolve(false);
    }

  });

  useEffect(() => {
    getMyReceiverInfo()
      .then((data) => {
        setReceiverInfos(data);
        if(!shipOrderId && data.length > 0) onChangeSuggess(data[0]);
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
      });
  }, [getMyReceiverInfo]);


  useEffect(() => {
    if(shipOrderId) {
      getShipOrder(shipOrderId).then((res) => {
        setBillCode(res.billCode);
        setWeight(res.weight);
        setTransportFee(res.transportFee);
        setProgressStatus(res.progressStatus);
        setJapanTrackingCode(res.japanTrackingCode);
        setVietnamTrackingCode(res.vietnamTrackingCode);
        setDescription(res.description);
        setCustomerNote(res.customerNote);
        setImages(res.images);
        setProductOrderIds(res.productOrderIds);
        setUserId(res.userId);
        setUserName(res.userName);
        setPaid(res.isPaid);
        setSenderAddress(res.senderAddress);
        setReceiverAddress(res.receiverAddress);
        setNomalProduct(res.nomalProduct?JSON.parse(res.nomalProduct):[]);
        setSpecialProduct(res.specialProduct?JSON.parse(res.specialProduct):[]);
        setReceiverPhoneNumber(res.receiverPhoneNumber);
        setReceiverName(res.receiverName);
        setPrice(res.price);
        setTransportType(res.transportType);
        setPaymentType(res.paymentType);
        setSenderName(res.senderName);
        setLength(res.length);
        setWidth(res.width);
        setHeight(res.height);
        setReceiverOrderId(res.receiverOrderId);
      })
    } else {
      setTransportType(EnumTransportType.HNAir);
      setPaymentType(EnumPaymentType.NormalWeight);
    }
  }, [shipOrderId]);


  useEffect(() => {
    if(productOrderIds.length > 0) {
      getMyProductOrderByIds(productOrderIds).then((res) => {
        setProductOrders(res);
      })
    }
  }, [getMyProductOrderByIds, productOrderIds])

  const onDeleteShipOrderImage = (file: ImageType | File) => {
    if (file instanceof File) {
      setImageFiles(imageFiles.filter((f) => f != file));
    } else {
      deleteShipOrderImage(shipOrderId, file.fileId)
        .then(() => {
          setImages(images.filter((i) => i.fileId != file.fileId));
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa ảnh thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        });
    }
  };

  const uploadImage = useCallback(
    (shipOrderId: number, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      return postShipOderImage(formData, shipOrderId);
    },
    [postShipOderImage],
  );

  const onAddImage = (file: File) => {
    if (file) {
      if (!shipOrderId) {
        setImageFiles([...imageFiles, file]);
      } else {
        uploadImage(shipOrderId, file)
          .then((res) => {
            setImages([...images, res]);
            addPopup({
              txn: {
                success: true,
                summary: 'Tải ảnh thành công',
              },
            });
          })
          .catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
          });
      }
    }
  };

  const onChangeSuggess = (value) => {
    setSuggessAddress(value);
    setReceiverName(value.fullname);
    setReceiverPhoneNumber(value.phoneNumber);
    setReceiverAddress(`${value.address1} ${value.address2} ${value.address3} ${value.address4}`);
  }

  //Main
  return (
    <>
      {props.isPopup ? null : (
        <ToolBar
          toolbarName={`Đơn vận chuyển ${billCode??japanTrackingCode}`}
          listRightButton={isDisable && progressStatus == EnumShipOrderProgressStatus.Waiting? [{
            name: 'Sửa',
            icon: 'edit',
            actionType: EnumAction.Edit,
            buttonClass: 'info100 sim-order-tool-btn',
            action: onChangeEditMode,
            align: 'center',
          }] : []}
          width={'100%'}
          backgroundColor={'#ebe9e9'}
          isPaging={false}
          isBack={true}
        />
      )}
      <div className={`shiporder-container ${props.isPopup? 'popup' : ''}`}>
        <div className='shiporder-info'>
          <ImageUpload
            images={shipOrderId ? images : imageFiles}
            onDelete={onDeleteShipOrderImage}
            addImage={onAddImage}
            showOnlyOne={true}
          />
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Mã phiếu"
              disabled={true}
              value={billCode}
              rightAction={() => {
                if(!isDisable) qrReaderModal.handlePresent({
                  readQR: (code: string) => {
                    setBillCode(code);
                    qrReaderModal.handleDismiss();
                  }
                }, "QUÉT MÃ VẬN CHUYỂN")
              }}
              rightIcon='qr_code_scanner'
            />
          </div>
          <div className="shiporder-row">
            <SelectBoxComponent
              width="360px"
              require={false}
              onChange={(value) => setTransportType(value)}
              isDisable={isDisable}
              title={'Hình thức'}
              value={transportType}
              data={TransportType}
              valueType={'value'}
              titleType={'title'}
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Khối lượng (kg)"
              require={false}
              disabled={isDisable}
              value={weight / 1000}
              onChange={(value) => {
                setWeight(Number(value) * 1000);
              }}
              type='number'
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Mã vận chuyển JP"
              disabled={isDisable}
              value={japanTrackingCode}
              onChange={setJapanTrackingCode}
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Mã vận chuyển VN"
              disabled={true}
              value={vietnamTrackingCode}
              onChange={setVietnamTrackingCode}
            />
            
          </div>
          <span className='shiporder-vn-tracking-guide'>Truy cập <span onClick={() => {
              navigator.clipboard.writeText(vietnamTrackingCode).then(() => {
                if(vietnamTrackingCode?.includes('YCBS')) {
                  window.open(`https://giaohangsieuviet.com/tracking/?code=${vietnamTrackingCode??''}`);
                } else {
                  window.open("https://viettelpost.com.vn/tra-cuu-hanh-trinh-don/");
                }
              });

            }}>Link</span> để tra cứu thông tin vận chuyển nội địa</span>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Mô tả"
              require={false}
              disabled={isDisable}
              value={description}
              onChange={setDescription}
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Địa chỉ người gửi"
              require={false}
              disabled={isDisable}
              value={senderAddress}
              onChange={setSenderAddress}
            />
          </div>
          {receiverInfos && receiverInfos.length > 0? <div className="shiporder-row">
            <SelectSearchComponent
              width="360px"
              onChange={onChangeSuggess}
              isDisable={isDisable}
              title={'Địa chỉ nhận gợi ý'}
              value={suggessAddress}
              list={receiverInfos}
              titleField='fullname'
              convert={(item) => {
                return `${item.fullname} | ${item.phoneNumber} | ${item.address1} ${item.address2} ${item.address3} ${item.address4}`;
              }}
            />
          </div> : null}
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Tên người nhận"
              require={false}
              disabled={isDisable}
              value={receiverName}
              onChange={setReceiverName}
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="SĐT người nhận"
              require={false}
              disabled={isDisable}
              value={receiverPhoneNumber}
              onChange={setReceiverPhoneNumber}
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Địa chỉ người nhận"
              require={false}
              disabled={isDisable}
              value={receiverAddress}
              onChange={setReceiverAddress}
            />
          </div>

          <div className="shiporder-row">
            <Input
              width="360px"
              title="Ghi chú khách hàng"
              require={false}
              disabled={isDisable}
              value={customerNote}
              onChange={setCustomerNote}
            />
          </div>

          {isDisable && <div className="shiporder-row">
            <SelectBoxComponent
              width="360px"
              require={true}
              onChange={setProgressStatus}
              isDisable={true}
              placeholder={'Trạng thái'}
              value={progressStatus}
              data={ShipOrderProgressStatusList}
              valueType={'value'}
              titleType={'title'}
            />
          </div>}

          {!isDisable && <div className="shiporder-row">
            <ButtonComponent width="360px" icon="save" title={'LƯU'} onClick={onSave} loader={true}/>
          </div>}
        </div>
        {shipOrderId > 0 && isDisable && productOrders?.length > 0? <div className='shiporder-info refproduct-table'>
          <span>Danh sách đơn mua hộ</span>
          {window.innerWidth > 600? <Table
            display={tableDisplay}
            isInput={false}
            data={productOrders}
            isNo={true}
          /> : <ListView
            display={display}
            isInput={false}
            data={productOrders}
            isNo={true}
          />}
        </div> : null}
        {shipOrderId > 0 && isDisable? (
          <div className='shiporder-info'>
            <Note
              objectId={shipOrderId}
              functionId={EnumDisplayConfig.ShipOrder}
              recordUserId={profile.info.userId}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ShipOrderDetail;
