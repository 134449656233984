import React, { useState, useEffect } from 'react';
import { ReceiverInfoType } from 'src/api/models';
import { useGetReceiverInfoById, usePutReceiverInfo } from 'src/api/receiverInfoApi';
import useProfile from 'src/hooks/useProfile';
import useQuery from 'src/hooks/useQuery';
import { useAddPopup } from 'src/state/application/hooks';
import './EditAccountAddress.css';

const EditAccountAddress: React.FC = () => {
  //Value
  const profile = useProfile();
  const query = useQuery();
  const receiverInfoId = Number(query.get('tid'));

  //State
  const [fullname, setFullname] = useState('');
  const [fullnameError, setFullnameError] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(null);

  const [zipCode, setZipCode] = useState('');

  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [address4, setAddress4] = useState('');
  const [addressError, setAddressError] = useState(null);

  const [facebook, setFacebook] = useState('');

  const [zalo, setZalo] = useState('');

  const [focusInput, setFocusInput] = useState(null);
  //End of state

  //Function
  const addPopup = useAddPopup();
  const putReceiverInfo = usePutReceiverInfo();
  const getReceiverIndoById = useGetReceiverInfoById();

  const onPutReceiverInfo = () => {
    const isFullName = validateFullName();
    const isPhoneNumber = validatePhoneNumber();
    const isAddress = validateAddress();
    if (isFullName && isPhoneNumber && isAddress && receiverInfoId) {
      const receiverInfo: ReceiverInfoType = {
        fullname: fullname,
        phoneNumber: phoneNumber,
        zipCode: zipCode,
        address1: address1,
        address2: address2,
        address3: address3,
        address4: address4,
        facebook: facebook,
        zalo: zalo,
        receiverInfoId: receiverInfoId,
      };
      putReceiverInfo(receiverInfo)
        .then((data) => {
          setFullname(data.fullname);
          setPhoneNumber(data.phoneNumber);
          setZipCode(data.zipCode);
          setAddress1(data.address1);
          setAddress2(data.address2);
          setAddress3(data.address3);
          setAddress4(data.address4);
          setFacebook(data.facebook);
          setZalo(data.facebook);
          addPopup({
            txn: {
              success: true,
              summary: 'Sửa địa chỉ thành công',
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //Validate
  const validateFullName = () => {
    let isContinue = true;

    if (!fullname || fullname == '') {
      isContinue = false;
      setFullnameError('Chưa nhập tên người nhận');
    } else setFullnameError(null);

    return isContinue;
  };

  const validatePhoneNumber = () => {
    let isContinue = true;

    if (!phoneNumber || phoneNumber == '') {
      isContinue = false;
      setPhoneNumberError('Chưa nhập số điện thoại');
    } else setPhoneNumberError(null);

    return isContinue;
  };

  const validateAddress = () => {
    let isContinue = true;

    if (
      (!address1 || address1 == '') &&
      (!address2 || address2 == '') &&
      (!address3 || address3 == '')
    ) {
      isContinue = false;
      setAddressError('Chưa địa chỉ');
    } else setAddressError(null);

    return isContinue;
  };
  //End of validate

  //Component
  //1
  const fullNameInput = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 1 ? 'focus-input' : ''} ${
            fullname ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Tên người nhận</div>
          <input
            type="text"
            value={fullname}
            onChange={(event) => {
              setFullname(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(1);
            }}
            onBlur={() => {
              validateFullName();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 1}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{fullnameError}</div>
      </div>
    );
  };

  //2
  const phoneNumberInput = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 2 ? 'focus-input' : ''} ${
            phoneNumber ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Số điện thoại</div>
          <input
            type="text"
            value={phoneNumber}
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(2);
            }}
            onBlur={() => {
              validatePhoneNumber();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 2}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{phoneNumberError}</div>
      </div>
    );
  };

  //3
  const zipCodeInput = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 3 ? 'focus-input' : ''} ${
            zipCode ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Mã bưu điện</div>
          <input
            type="text"
            value={zipCode}
            onChange={(event) => {
              setZipCode(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(3);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            placeholder={'Chỉ dùng ở Nhật Bản'}
            autoFocus={focusInput == 3}
          />
        </div>
      </div>
    );
  };

  //4
  const address1Input = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 4 ? 'focus-input' : ''} ${
            address1 ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Tỉnh, thành phố</div>
          <input
            type="text"
            value={address1}
            onChange={(event) => {
              setAddress1(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(4);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 4}
          />
        </div>
      </div>
    );
  };

  //5
  const address2Input = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 5 ? 'focus-input' : ''} ${
            address2 ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Quận, huyện</div>
          <input
            type="text"
            value={address2}
            onChange={(event) => {
              setAddress2(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(5);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 5}
          />
        </div>
      </div>
    );
  };

  //6
  const address3Input = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 6 ? 'focus-input' : ''} ${
            address3 ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Xã, phường, thị trấn</div>
          <input
            type="text"
            value={address3}
            onChange={(event) => {
              setAddress3(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(6);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 6}
          />
        </div>
      </div>
    );
  };

  //7
  const address4Input = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 7 ? 'focus-input' : ''} ${
            address4 ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Số nhà, tên đường</div>
          <input
            type="text"
            value={address4}
            onChange={(event) => {
              setAddress4(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(7);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 7}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{addressError}</div>
      </div>
    );
  };

  //8
  const facebookInput = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 8 ? 'focus-input' : ''} ${
            facebook ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Link Fb</div>
          <input
            type="text"
            value={facebook}
            onChange={(event) => {
              setFacebook(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(8);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 8}
          />
        </div>
      </div>
    );
  };

  //9
  const zaloInput = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 9 ? 'focus-input' : ''} ${
            zalo ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Zalo</div>
          <input
            type="text"
            value={zalo}
            onChange={(event) => {
              setZalo(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(9);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 9}
          />
        </div>
      </div>
    );
  };

  const addComponent = () => {
    return (
      <div className={`row justify-content-center p-2 m-0 address-wrapper `}>
        <div className="col-lg-11 col-12 m-0 p-0">
          <div className="dashboard_title">Địa chỉ nhận hàng</div>
          <div className="address-component_child mt-2">
            <div className="add-cpn_child_column">
              {fullNameInput()}
              {phoneNumberInput()}
              {zipCodeInput()}
              {facebookInput()}
              {zaloInput()}
            </div>
            <div className="add-cpn_child_column">
              {address1Input()}
              {address2Input()}
              {address3Input()}
              {address4Input()}
            </div>

            <div className="add-address_btn_container">
              <button
                className="dashboard_button_child add-address"
                onClick={() => {
                  onPutReceiverInfo();
                }}
              >
                Lưu
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //End of component

  //useEffect
  useEffect(() => {
    if (receiverInfoId) {
      getReceiverIndoById(receiverInfoId)
        .then((data) => {
          setFullname(data.fullname);
          setPhoneNumber(data.phoneNumber);
          setZipCode(data.zipCode);
          setAddress1(data.address1);
          setAddress2(data.address2);
          setAddress3(data.address3);
          setAddress4(data.address4);
          setFacebook(data.facebook);
          setZalo(data.facebook);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getReceiverIndoById, receiverInfoId]);

  //Main
  return (
    <div className="container-fluid dashboard-container p-0">{addComponent()}</div>
  );
};

export default EditAccountAddress;
