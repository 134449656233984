/* eslint-disable react-hooks/exhaustive-deps */
import './Payment.css';
import React from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import { EnumAction, EnumDataType, PaymentType } from 'src/api/models';
import { useGetMyPayments } from 'src/api/paymentApi';
import { EnumPaymentStatus } from 'src/common/enum/EnumPaymentStatus';
import PaymentDetail from './PaymentDetail/PaymentDetail';
import { ItemDisplayType } from 'src/components/ListView/ItemView/ItemView';
import ListView from 'src/components/ListView/ListView';


const Payment: React.FC = () => {
  const [count, setCount] = useState<number>(0);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [payments, setPayments] = useState<PaymentType[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();

  //Function
  const addPopup = useAddPopup();
  const getMyPayments = useGetMyPayments();

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(PaymentDetail);

  const lstStatus = [
    {
      value: EnumPaymentStatus.Waiting,
      title: 'Chờ thanh toán',
      css: { color: 'red' },
    },
    {
      value: EnumPaymentStatus.Paid,
      title: 'Đã thanh toán',
      css: { color: 'blue' },
    },
  ];
  const display = {
      header: [
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày tạo',
      },
      {
        code: 'paymentCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã thanh toán',
      },
      {
        code: 'shipOrder',
        dataType: EnumDataType.HTML,
        isOptions: false,
        title: 'Đơn vch',
        convert: (value: string) => {
          return (<div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', fontSize: 'smaller'}}>
            {(value? JSON.parse(value) as [] : []).map((item, index) => (
                <div key={`option${index}`} style={{margin: "2px", width: 'fit-content', borderRadius: "5px", padding: "2px 5px", background: "black", color: "orange"}}>{item}</div>
            ))}
          </div>
          );
        },
      },
      {
        code: 'customerName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên khách',
      },
      {
        code: 'customerEmail',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Email',
      },
      {
        code: 'money',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Số tiền',
      },

      {
        code: 'expirationDate',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Hạn thanh toán',
      },
      {
        code: 'status',
        dataType: EnumDataType.Int,
        isOptions: true,
        title: 'Trạng thái',
        options: lstStatus
      },
    ] as TableColumnType[]
  };

  const viewDisplay: ItemDisplayType<PaymentType> = {
    header: [
      {
        code: 'paymentCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        cellCss: {
          display: 'flex',
          flex: 'auto'
        }
      },
      {
        code: 'status',
        dataType: EnumDataType.Text,
        isOptions: true,
        options: lstStatus,
        cellCss: {
          display: 'flex',
          background: 'lightgreen',
          borderRadius: '5px',
        }
      }
    ],
    detail: {
      info: [
        [
          {
            code: 'customerEmail',
            dataType: EnumDataType.Text,
            isOptions: false,
            icon: 'person',
          }
        ],
        [
          {
            code: 'customerName',
            dataType: EnumDataType.Text,
            isOptions: false,
            icon: 'badge',
            cellCss: {
              flex: 'auto'
            }
          },
          {
            code: 'money',
            dataType: EnumDataType.VND,
            isOptions: false,
            cellCss: {
              margin: '0 5px',
            }
          }
        ],
      ]
    },
    bottom: [
      [
        {
          code: 'createdAt',
          dataType: EnumDataType.Date,
          isOptions: false,
          cellCss: {
            flex: 'auto',
          }
        },
        {
          code: 'expirationDate',
          dataType: EnumDataType.Date,
          isOptions: false,
          cellCss: {
            background: 'orange',
            borderRadius: '5px',
          }
        },
      ]
    ],
    actions: (item: PaymentType) => [
      {
        icon: 'visibility',
        actionType: EnumAction.View,
        action: () => onView(item.paymentCode),
        buttonClass: 'info600',
        align: '',
      }
    ]
  };



  const onView = (paymentCode: string) => {
    window.open(`/payment/${paymentCode}`);
  };


  useEffect(() => {
    getMyPayments(keyword, page, size)
      .then((data) => {
        setPayments(data.items);
        setCount(data.count);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [
    addPopup,
    getMyPayments,
    reloadFlag,
    keyword,
    page,
    size
  ]);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH THANH TOÁN'}
        listRightButton={[]}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
      />

      {window.innerWidth > 600? <Table
        display={display}
        isInput={false}
        data={payments}
        allowCheckbox={false}
        onDoubleClick={(item) => onView(item.paymentCode)}
        isNo={true}
      /> : <ListView
        display={viewDisplay}
        isInput={false}
        data={payments}
        onDoubleClick={(item) => onView(item.paymentCode)}
        isNo={true}
      />}
    </>
  );
};
export default Payment;
