import React from 'react';
import Images from 'src/assets/img';
import './ListLinkBaseHome.css';
import { useNavigate } from 'react-router-dom';

const ListLinkBaseHome: React.FC = () => {
  //Value
  const navigate = useNavigate();

  //Function
  const onClickLink = (link: string, blank: boolean) => {
    if (blank) {
      window.open(link, '_blank');
    } else {
      navigate(link);
    }
  };

  //Main
  return (
    <div className="container-fluid mt-3">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-11 p-0 m-0 base-home_ecommerce-list mt-3">
          <div
            className="base-home_ecommerce-list-child"
            onClick={() => {
              const url = 'https://auctions.yahoo.co.jp/';
              onClickLink(url, true);
            }}
          >
            <img
              className="base-home_ecommerce-list-child-img"
              src={Images.yahoo_auction_icon}
              alt=""
            />
            Yahoo!Auction
          </div>
          <div
            className="base-home_ecommerce-list-child"
            onClick={() => {
              const url = 'https://www.rakuten.co.jp/';
              onClickLink(url, true);
            }}
          >
            <img
              className="base-home_ecommerce-list-child-img"
              src={Images.rakuten_icon}
              alt=""
            />
            Rakuten
          </div>
          <div
            className="base-home_ecommerce-list-child"
            onClick={() => {
              const url = 'https://www.amazon.co.jp/';
              onClickLink(url, true);
            }}
          >
            <img
              className="base-home_ecommerce-list-child-img"
              src={Images.amazon_icon}
              alt=""
            />
            Amazon
          </div>
          <div
            className="base-home_ecommerce-list-child"
            onClick={() => {
              const url = 'https://jp.mercari.com/';
              onClickLink(url, true);
            }}
          >
            <img
              className="base-home_ecommerce-list-child-img"
              src={Images.mercari_icon}
              alt=""
            />
            Mercari
          </div>
          <div
            className="base-home_ecommerce-list-child"
            onClick={() => {
              const url = 'https://shopping.yahoo.co.jp/';
              onClickLink(url, true);
            }}
          >
            <img
              className="base-home_ecommerce-list-child-img"
              src={Images.yahoo_shopping_icon}
              alt=""
            />
            Yahoo!Shopping
          </div>
          <div
            className="base-home_ecommerce-list-child"
            onClick={() => {
              const url = 'https://paypaymall.yahoo.co.jp/';
              onClickLink(url, true);
            }}
          >
            <img
              className="base-home_ecommerce-list-child-img"
              src={Images.paypay_icon}
              alt=""
            />
            Paypay
          </div>
          <div
            className="base-home_ecommerce-list-child full"
            onClick={() => {
              //TODO
              const url = 'hello';
              onClickLink(url, true);
            }}
          >
            <img
              className="base-home_ecommerce-list-child-img"
              src={Images.customer_order_icon}
              alt=""
            />
            Đặt hàng website Nhật theo yêu cầu
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListLinkBaseHome;
