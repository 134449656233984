/************************************/
/********                    ********/
/******   Ship Order   ********/
/******   Writen by HuyLV      ****/
/********                   ********/
/***********************************/

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { ImageType, PageData } from './models';
import { ShipOrderType } from './models';

export const useGetShipOrderById = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/${shipOrderId}`,
        method: 'get',
      }) as Promise<ShipOrderType>;
    },
    [fetch],
  );
};

export const useGetShipOrderByCode = () => {
  const fetch = useFetch();
  return useCallback(
    (code: string) => {
      return fetch({
        url: `sccore/rest-api/ship-order/code/${code}`,
        method: 'get',
      }) as Promise<ShipOrderType>;
    },
    [fetch],
  );
};

export const useDeleteShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/${shipOrderId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePostShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrder: ShipOrderType) => {
      return fetch({
        url: `sccore/rest-api/ship-order`,
        method: 'post',
        data: shipOrder,
      }) as Promise<ShipOrderType>;
    },
    [fetch],
  );
};

export const usePutShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrder: ShipOrderType) => {
      return fetch({
        url: `sccore/rest-api/ship-order`,
        method: 'put',
        data: shipOrder,
      }) as Promise<ShipOrderType>;
    },
    [fetch],
  );
};

export const useGetMyShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/me?keyword=${keyword}&page=${page}&size=${size}`,
        method: 'get',
      }) as Promise<PageData<ShipOrderType>>;
    },
    [fetch],
  );
};

export const usePrintShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/print/${shipOrderId}`,
        method: 'post',
      }) as Promise<string>;
    },
    [fetch],
  );
};

export const useGetProcessedShipOrder = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/ship-order/me?progressStatus=1`,
      method: 'get',
    }) as Promise<PageData<ShipOrderType>>;
  }, [fetch]);
};

export const usePostMyShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrder: ShipOrderType) => {
      return fetch({
        url: `sccore/rest-api/ship-order/me`,
        method: 'post',
        data: shipOrder,
      }) as Promise<ShipOrderType>;
    },
    [fetch],
  );
};

export const usePutMyShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrder: ShipOrderType) => {
      return fetch({
        url: `sccore/rest-api/ship-order/me`,
        method: 'put',
        data: shipOrder,
      }) as Promise<ShipOrderType>;
    },
    [fetch],
  );
};


export const usePostShipOrderImage = () => {
  const fetch = useFetch(false, true);
  return useCallback(
    (data, shipOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/image/${shipOrderId}`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteShipOrderImage = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrderId: number, fileId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/${shipOrderId}/image/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of Ship Order
