import React, { useEffect, useState } from 'react';
import Images from 'src/assets/img';
import Banner from 'src/components/Banner/Banner';
import ListLinkBaseHome from 'src/components/ListLinkBaseHome/ListLinkBaseHome';
import SearchAndTrackingBaseHome from 'src/components/SearchAndTrackingBaseHome/SearchAndTrackingBaseHome';
import ServiceBaseHome from 'src/components/ServiceBaseHome/ServiceBaseHome';
import WhyChooseJVS from 'src/components/WhyChooseJVS/WhyChooseJVS';
import useProfile from 'src/hooks/useProfile';
import { useAddPopup } from 'src/state/application/hooks';
import './BaseHome.css';
import { useGetBanner } from 'src/api/bannerApi';
import { Navigate } from 'react-router-dom';

const BaseHome: React.FC = () => {
  //CONST
  const profile = useProfile();
  //State
  const [listBanner, setListBanner] = useState([]);

  //Function
  const getBanner = useGetBanner();
  const addPopup = useAddPopup();

  useEffect(() => {
    getBanner()
      .then((data) => {
        setListBanner(data);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [addPopup, getBanner]);

  //Component
  // const banner = () => {
  //   return (
  //     <div className="container-fluid">
  //       <div className="row base-home-wrapper">
  //         <div className="base-home_banner_content">
  //           <img src={Images.logo} alt="" />
  //           <div className="base-home_banner_content-child mt-lg-3 m-0">
  //             Trao giá trị, nhận niềm tin
  //           </div>
  //         </div>
  //         <div className="base-home_banner_img">
  //           <Banner listImages={listBanner} />
  //           <div
  //             className="base-home_banner-title"
  //             dangerouslySetInnerHTML={{
  //               __html: titleString,
  //             }}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  //End of component

  //Main
  return (
    !profile ? <div className="base-home-container">
      {/* {banner()} */}
      <ListLinkBaseHome />
      <SearchAndTrackingBaseHome />
      <ServiceBaseHome />
      <WhyChooseJVS />
    </div> : <Navigate to="/dashboard"/>
  );
};

export default BaseHome;
