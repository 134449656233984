import React, { useEffect, useState } from 'react';
import { EnumDisplayConfig, NoteType } from 'src/api/models';
import { useGetNote, usePostNote } from 'src/api/noteApi';
import { useGetPurchaseAccountById } from 'src/api/purchaseAccountApi';
import useProfile from 'src/hooks/useProfile';
import useQuery from 'src/hooks/useQuery';
import { useAddPopup } from 'src/state/application/hooks';
import './EditPurchaseAccount.css';

type NoteByDay = {
  createdAt: string;
  list: NoteType[];
};

const EditPurchaseAccount: React.FC = () => {
  //Value
  const profile = useProfile();
  const query = useQuery();
  const purchaseAccountId = Number(query.get('tid'));

  //State
  const [purchasePage, setPurchasePage] = useState<string>(null);
  const [accountName, setAccountName] = useState<string>(null);
  const [accountPassword, setAccountPassword] = useState<string>(null);
  const [description, setDescription] = useState<string>(null);
  const [note, setNote] = useState<string>(null);

  const [focusInput, setFocusInput] = useState(null);
  const [noteList, setNoteList] = useState<NoteByDay[]>(null);
  const [noteContent, setNoteContent] = useState('');
  const [reloadFlag, setReloadFlag] = useState<boolean>(false);
  //End of state

  //Function
  const addPopup = useAddPopup();
  const getPurchaseAccountById = useGetPurchaseAccountById();
  const getNote = useGetNote();
  const postNote = usePostNote();

  const onPostNote = () => {
    if (purchaseAccountId && noteContent != '') {
      const note: NoteType = {
        functionId: EnumDisplayConfig.PurchaseAccount,
        objectId: Number(purchaseAccountId),
        noteContent: noteContent,
      };
      postNote(note, profile.info.userId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Gửi thành công!',
            },
          });
          setNoteContent('');
          setReloadFlag(!reloadFlag);
        })
        .catch((e) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: e.errorMessage,
            },
          });
          setReloadFlag(!reloadFlag);
        });
    }
  };
  //End of function

  //Component
  //2
  const purchasePageInput = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 2 ? 'focus-input' : ''} ${
            purchasePage ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Loại tài khoản</div>
          <input
            type="text"
            value={purchasePage}
            onFocus={() => {
              setFocusInput(2);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 2}
            disabled={true}
          />
        </div>
      </div>
    );
  };

  //3
  const accountNameInput = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 3 ? 'focus-input' : ''} ${
            accountName ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Tên đăng nhập</div>
          <input
            type="text"
            value={accountName}
            onFocus={() => {
              setFocusInput(3);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 3}
            disabled={true}
          />
        </div>
      </div>
    );
  };

  //4
  const accountPasswordInput = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 4 ? 'focus-input' : ''} ${
            accountPassword ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Mật khẩu</div>
          <input
            type="text"
            value={accountPassword}
            onFocus={() => {
              setFocusInput(4);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 4}
            disabled={true}
          />
        </div>
      </div>
    );
  };

  //5
  const descriptionInput = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 5 ? 'focus-input' : ''} ${
            description ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Mô tả</div>
          <input
            type="text"
            value={description}
            onFocus={() => {
              setFocusInput(5);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 5}
            disabled={true}
          />
        </div>
      </div>
    );
  };

  //6
  const noteInput = () => {
    return (
      <div className="address-common_input_wrapper">
        <div
          className={`address-common_input ${focusInput == 6 ? 'focus-input' : ''} ${
            note ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Ghi chú</div>
          <input
            type="text"
            value={note}
            onFocus={() => {
              setFocusInput(6);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 6}
            disabled={true}
          />
        </div>
      </div>
    );
  };

  const infoComponent = () => {
    return (
      <div className="address-component_child mt-2">
        {purchasePageInput()}
        {accountNameInput()}
        {accountPasswordInput()}
        {descriptionInput()}
        {noteInput()}
      </div>
    );
  };

  //100
  const noteComponent = () => {
    return (
      <div className="address-component_child mt-2 p-0">
        {noteList
          ? noteList.map((v) => {
              return (
                <div className="note-sim-component">
                  {v.list.map((item, index) => {
                    return (
                      <div
                        className={`note-sim-item ${
                          item.userId == profile.info.userId ? 'my' : ''
                        } `}
                      >
                        {index >= 1 && item.userId == v.list[index - 1].userId ? (
                          <div className="note-item-avt none">hello</div>
                        ) : (
                          <div className="note-item-avt">
                            {item.avataUrl ? (
                              <img src={item.avataUrl} alt="avatar" />
                            ) : (
                              <div className="no-sim-avt"></div>
                            )}
                          </div>
                        )}
                        <div className="note-sim-detail">
                          <div>
                            <b>{item.fullName}</b>
                          </div>
                          <div>{item.noteContent}</div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="note-sim-date">
                    <div className="note-sim-line">line</div>
                    <div>{v.createdAt}</div>
                    <div className="note-sim-line">line</div>
                  </div>
                </div>
              );
            })
          : null}
        <div className="ship-od-detail_100">
          <div
            className={`login-input ship-od mb-2 mt-1 ${
              focusInput == 100 ? 'focus-input' : ''
            } ${noteContent ? 'validate-input' : ''}`}
          >
            <div className="login-input-title">Nhập nội dung</div>
            <input
              type="text"
              value={noteContent}
              onChange={(event) => {
                setNoteContent(event.target.value);
              }}
              onFocus={() => {
                setFocusInput(100);
              }}
              onBlur={() => {
                setFocusInput(null);
              }}
              autoFocus={focusInput == 100}
            />
          </div>
          <button
            className="dashboard_button_child"
            onClick={() => {
              onPostNote();
            }}
          >
            Gửi
          </button>
        </div>
      </div>
    );
  };

  const detailComponent = () => {
    return (
      <div className={`row justify-content-center p-0 ml-2 mr-2 mt-2 mb-0 address-wrapper`}>
        <div className="col-12 m-0 p-0 pro_order_detail_wrapper">
          <div className="account-btn">Thông tin</div>
          {infoComponent()}
          <div className="account-btn">Trao đổi</div>
          {noteComponent()}
        </div>
      </div>
    );
  };
  //End of component

  useEffect(() => {
    if (purchaseAccountId) {
      getPurchaseAccountById(purchaseAccountId)
        .then((data) => {
          setPurchasePage(data.purchasePage);
          setAccountName(data.accountName);
          setAccountPassword(data.accountPassword);
          setDescription(data.description);
          setNote(data.note);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getPurchaseAccountById, purchaseAccountId]);

  useEffect(() => {
    if (purchaseAccountId) {
      getNote(EnumDisplayConfig.PurchaseAccount, Number(purchaseAccountId))
        .then((r) => {
          if (r.length > 0) {
            const data = r;
            data.reverse();

            let cr = data[0].createdAt;
            let tempArr: NoteType[] = [];
            const mainArr: NoteByDay[] = [];

            for (let i = 0; i <= data.length; i++) {
              if (i < data.length) {
                if (data[i].createdAt == cr) {
                  tempArr.push(data[i]);
                } else {
                  const temp: NoteByDay = {
                    createdAt: cr,
                    list: tempArr,
                  };
                  mainArr.push(temp);
                  cr = data[i].createdAt;
                  tempArr = [data[i]];
                }
              } else {
                const temp: NoteByDay = {
                  createdAt: cr,
                  list: tempArr,
                };
                mainArr.push(temp);
              }
            }

            setNoteList(mainArr);
          }
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getNote, reloadFlag, purchaseAccountId]);

  return (
    <div className="container-fluid dashboard-container">
      <div className="dashboard_title">Chi tiết TK đã cấp</div>
      {detailComponent()}
    </div>
  );
};

export default EditPurchaseAccount;
