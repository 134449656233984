import loginImage from './img-dk-mobile.png';
import logo from './logo.png';
import error from './1200px-Error.svg.png';
import orderImage from './order.png';
import shippingImage from './shipping.png';
import bg_banner from './bg-banner.png';
import amazon_icon from './amazon-icon.png';
import customer_order_icon from './customer-order-icon.png';
import mercari_icon from './mercari-icon.png';
import paypay_icon from './paypay-icon.png';
import rakuten_icon from './rakuten-icon.png';
import yahoo_auction_icon from './yahoo-auction-icon.png';
import yahoo_shopping_icon from './yahoo-shopping-icon.png';
import dau_gia from './dau_gia.png';
import dam_phan from './dam_phan.png';
import thanh_toan from './thanh_toan.png';
import van_chuyen from './van_chuyen.png';
import xuat_nhap_khau from './xuat_nhap_khau.png';
import made_in_jp from './madein-jp.png';
import paft from './paft.png';
import no_product_order_list from './no-product-order-list.png';
import loading_icon from './icon-loading.gif';
import tracking from './tracking.png';
import hacker from './hacker.jpg';
import transport from './transport.jpg';
import truck from './truck.jpg';

const Images = {
  loginImage: loginImage,
  logo: logo,
  error: error,
  orderImage: orderImage,
  shippingImage: shippingImage,
  bg_banner: bg_banner,
  amazon_icon: amazon_icon,
  customer_order_icon: customer_order_icon,
  mercari_icon: mercari_icon,
  paypay_icon: paypay_icon,
  rakuten_icon: rakuten_icon,
  yahoo_auction_icon: yahoo_auction_icon,
  yahoo_shopping_icon: yahoo_shopping_icon,
  dau_gia: dau_gia,
  dam_phan: dam_phan,
  thanh_toan: thanh_toan,
  van_chuyen: van_chuyen,
  xuat_nhap_khau: xuat_nhap_khau,
  made_in_jp: made_in_jp,
  paft: paft,
  no_product_order_list: no_product_order_list,
  loading_icon: loading_icon,
  tracking: tracking,
  hacker: hacker,
  transport: transport,
  truck: truck,
};

export default Images;
