import React from 'react';
import Images from 'src/assets/img';
import './ServiceBaseHome.css';

const ServiceBaseHome: React.FC = () => {
  //Main
  return (
    <div className="container-fluid mt-lg-5 mt-3">
      <div className="row justify-content-center p-0">
        <div className="col-lg-9 col-11 p-0 m-0 mt-3 service-base-home">
          <h3 className="service-base-home_title">Dịch vụ</h3>
          <div className="service-base-home_main">
            <div className="service-base-home_main_child">
              <img className="service_main_child_img" src={Images.dau_gia} alt="" />
              Đấu giá
            </div>
            <div className="service-base-home_main_child">
              <img className="service_main_child_img" src={Images.thanh_toan} alt="" />
              Thanh toán - mua hộ
            </div>
            <div className="service-base-home_main_child">
              <img className="service_main_child_img" src={Images.van_chuyen} alt="" />
              Vận chuyển quốc tế
            </div>
            <div className="service-base-home_main_child">
              <img className="service_main_child_img" src={Images.dam_phan} alt="" />
              Đàm phán thương mại
            </div>
            <div className="service-base-home_main_child">
              <img className="service_main_child_img" src={Images.xuat_nhap_khau} alt="" />
              Xuất nhập khẩu chính ngạch
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceBaseHome;
