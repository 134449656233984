/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { EnumAction, EnumDisplayConfig, EnumProductOrderProgressStatus, NoteType, ProductOrderType } from 'src/api/models';
import { useGetNote, usePostNote } from 'src/api/noteApi';
import { useGetProductOrderById, usePostMyProductOrder, usePostProductOrderImage, usePutMyProductOrder } from 'src/api/productOrdrerApi';
import useProfile from 'src/hooks/useProfile';
import useQuery from 'src/hooks/useQuery';
import { useAddPopup } from 'src/state/application/hooks';
import './ProductOrderDetail.css';
import { useParams } from 'react-router-dom';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Avatar from 'src/components/Avatar';
import Input from 'src/components/Input';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Note from 'src/components/Note/Note';

interface IProductOrderDetail {
  productOrderId?: number;
  isDisable?: boolean;
  isPopup?: boolean;
  postProcess?: (...args: any[]) => void;
}


const ProductOrderDetail: React.FC<IProductOrderDetail> = (props) => {
  const lstStatus = [
    {
      value: EnumProductOrderProgressStatus.Waiting,
      title: 'Chờ duyệt',
      css: { color: '#3a3131' },
    },
    {
      value: EnumProductOrderProgressStatus.Bought,
      title: 'Đã mua',
      css: { color: 'blue' },
    },
    {
      value: EnumProductOrderProgressStatus.JapanStore,
      title: 'Đã về kho JP',
      css: { color: 'green' },
    },
  ];

  const [productOrderId, setProductOrderId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);
  const params = useParams<{type: string; productOrderId: string }>();
  const profile = useProfile();
  const navigate = props.isPopup ? undefined : useNavigate();

  useEffect(() => {
    setProductOrderId(props.productOrderId || Number(params.productOrderId));
    setIsDisable(Number(params.type) == EnumViewType.View || props.isDisable);
  }, [params.productOrderId, params.type, props.isDisable, props.productOrderId]);

  //State
  const [productImage, setProductImage] = useState<string>();
  const [imageFile, setImageFile] = useState<File>();

  const [productUrl, setProductUrl] = useState<string>();
  const [productName, setProductName] = useState<string>();
  const [productPrice, setProductPrice] = useState<number>();
  const [description, setDescription] = useState<string>();
  const [quantity, setQuantity] = useState<number>();
  const [productOrderFee, setProductOrderFee] = useState<number>();
  const [progressStatus, setProgressStatus] = useState<EnumProductOrderProgressStatus>(EnumProductOrderProgressStatus.Waiting);
  const [shipOrderId, setShipOrderId] = useState<number>();
  const [note, setNote] = useState<string>();
  const [japanTrackingCode, setJapanTrackingCode] = useState<string>();
  //End of state

  //Function
  const addPopup = useAddPopup();
  const getProductOrderById = useGetProductOrderById();
  const postProductOrderImage = usePostProductOrderImage();
  const postProductOrder = usePostMyProductOrder();
  const putProductOrder = usePutMyProductOrder();

  const onChangeImage = (file: File) => {
    if (file) {
      const url = URL.createObjectURL(file);
      setProductImage(url);
      setImageFile(file);
    }
  };

  const onSuccess = (res: ProductOrderType) => {
    addPopup({
      txn: {
        success: true,
        summary: productOrderId? 'Thay đổi đơn mua hộ thành công' : 'Tạo đơn mua hộ thành công',
      }
    });
    if(props.postProcess) props.postProcess(res);
  }

  const uploadImage = (productOrderId: number, file: File) => new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    postProductOrderImage(formData, productOrderId).then((data) => {
      setProductImage(data.fileUrl);
      resolve(data);
    }).catch((error) => reject(error));
  });

  const onChangeEditMode = (e: MouseEvent) => {
    navigate(`/product-order/${productOrderId}/${EnumViewType.Edit}`);
  };

  const onSave = () => {
    if (description?.trim().length > 0) {
      const productOrder: ProductOrderType = {
        productOrderId: productOrderId,
        productUrl: productUrl,
        productName: productName,
        productPrice: productPrice,
        quantity: quantity,
        note: note,
        description: description,
        japanTrackingCode: japanTrackingCode
      };
      const api = productOrderId? putProductOrder : postProductOrder;
      api(productOrder).then(
        (res) => {
          setProductOrderId(res.productOrderId);
          setIsDisable(true);
          if(imageFile) {
            uploadImage(res.productOrderId, imageFile).then(() => {
              onSuccess(res);
            }).catch((error) => addPopup({
              error: {
                message: error.errorMessage,
                title: 'Tải ảnh đơn mua hộ thất bại!',
              },
            }))
          } else {
            onSuccess(res);
          }
        })
        .catch((error) => {
          console.log(error);
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa nhập mô tả sản phẩm thông tin',
        },
      });
    }
  };


  useEffect(() => {
    if (productOrderId) {
      getProductOrderById(productOrderId)
        .then((data) => {
          setProductUrl(data.productUrl);
          setProductName(data.productName);
          setProductPrice(data.productPrice);
          setDescription(data.description);
          setQuantity(data.quantity);
          setProductImage(data.productImage);
          setProductOrderFee(data.productOrderFee);
          setProgressStatus(data.progressStatus);
          setShipOrderId(data.shipOrderId);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getProductOrderById, productOrderId]);


  //Main
  return (
    <>
      {props.isPopup ? null : (
        <ToolBar
          toolbarName={`Đơn mua hộ ${productName}`}
          listRightButton={isDisable && progressStatus == EnumProductOrderProgressStatus.Waiting? [{
            name: 'Sửa',
            icon: 'edit',
            actionType: EnumAction.Edit,
            buttonClass: 'info100 sim-order-tool-btn',
            action: onChangeEditMode,
            align: 'center',
          }] : []}
          width={'100%'}
          backgroundColor={'#ebe9e9'}
          isPaging={false}
          isBack={true}
        />
      )}
      <div className={`productorder-container ${props.isPopup? 'popup' : ''}`}>
        <div className='productorder-info'>
          <Avatar
            change={onChangeImage}
            thumbAvatar={productImage}
            avatar={productImage}
            disabled={isDisable}
          />
          <div className="productorder-row">
            <Input
              width="320px"
              title="Link mua SP"
              require={false}
              disabled={isDisable}
              value={productUrl}
              onChange={setProductUrl}
            />
          </div>
          <div className="productorder-row">
            <Input
              width="320px"
              title='Giá SP'
              require={false}
              disabled={isDisable}
              value={productPrice}
              onChange={setProductPrice}
              type='number'
            />
          </div>
          <div className="productorder-row">
            <Input
              width="320px"
              title='Số lượng'
              require={false}
              disabled={isDisable}
              value={quantity}
              onChange={setQuantity}
              type='number'
            />
          </div>

          <div className="productorder-row">
            <Input
              width="320px"
              title="Mã vận chuyển JP"
              disabled={isDisable}
              value={japanTrackingCode}
              onChange={setJapanTrackingCode}
            />
          </div>
          <div className="productorder-row">
            <Input
              width="320px"
              title="Mô tả"
              require={false}
              disabled={isDisable}
              value={description}
              onChange={setDescription}
            />
          </div>

          <div className="productorder-row">
            <Input
              width="320px"
              title="Ghi chú"
              require={false}
              disabled={isDisable}
              value={note}
              onChange={setNote}
            />
          </div>

          <div className="productorder-row">
            <SelectBoxComponent
              width="320px"
              require={true}
              onChange={setProgressStatus}
              isDisable={true}
              placeholder={'Trạng thái'}
              value={progressStatus}
              data={lstStatus}
              valueType={'value'}
              titleType={'title'}
            />
          </div>
          {productOrderId > 0 && isDisable && <div className="productorder-row refshiporder">
            {shipOrderId? <a onClick={() => navigate(`/ship-order/${shipOrderId}`)}>Xem đơn vận chuyển</a> : <span>Chưa tạo đơn vận chuyển. Vui lòng liên hệ BQT</span>}
          </div>}
          {!isDisable && <div className="productorder-row">
            <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} />
          </div>}
        </div>
        {productOrderId > 0 && isDisable? (
          <div className='productorder-info'>
            <Note
              objectId={productOrderId}
              functionId={EnumDisplayConfig.ProductOrder}
              recordUserId={profile.info.userId}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ProductOrderDetail;
