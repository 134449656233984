import React, { useState, useEffect } from 'react';
import { EnumAction, EnumDataType, EnumShipOrderProgressStatus, EventButton, ShipOrderProgressStatusList, ShipOrderType, TransportType } from 'src/api/models';
import { useDeleteShipOrder, useGetMyShipOrder, usePrintShipOrder } from 'src/api/shipOrderApi';
import './ShipOrder.css';
import { useNavigate } from 'react-router-dom';
import Table from 'src/components/Table/Table';
import { useAddPopup } from 'src/state/application/hooks';
import ToolBar from 'src/components/ToolBar/ToolBar';
import ListView from 'src/components/ListView/ListView';
import { ItemDisplayType } from 'src/components/ListView/ItemView/ItemView';
import useModal from 'src/hooks/useModal';
import Printed from 'src/components/Printed/Printed';
import ShipOrderDetail from './ShipOrderDetail/ShipOrderDetail';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { numberFormat } from 'src/utils/numberUtils';

const ShipOrderList: React.FC = () => {
  const navigate = useNavigate();

  //State
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [count, setCount] = useState(1);
  const addPopup = useAddPopup();
  const printedModal = useModal(Printed);
  const [shipOrderList, setShipOrderList] = useState<ShipOrderType[]>([]);
  const detailModal = useModal(ShipOrderDetail);
  const confirmModal = useModal(ConfirmModal);

  const [reloadFlag, setReloadFlag] = useState(false);

  const display: ItemDisplayType<ShipOrderType> = {
    header: [
      {
        code: 'billCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        cellCss: {
          display: 'flex',
          flex: 'auto'
        }
      },
      {
        code: 'progressStatus',
        dataType: EnumDataType.Text,
        isOptions: true,
        options: ShipOrderProgressStatusList,
        cellCss: {
          display: 'flex',
          background: 'lightgreen',
          borderRadius: '5px',
        }
      }
    ],
    detail: {
      avatar: 'images[0]?.thumbUrl',
      info: [
        [
          {
            code: 'receiverOrderName',
            dataType: EnumDataType.Text,
            isOptions: false,
            cellCss: {
              display: 'flex',
              flex: 'auto'
            }
          },
          {
            code: 'userName',
            dataType: EnumDataType.Text,
            isOptions: false,
            cellCss: {
              display: 'flex',
              flex: 'auto'
            }
          },
        ],
        [
          {
            code: 'weight',
            dataType: EnumDataType.HTML,
            convert: (value: number) => {
              return value? <div style={{}}>
                <span className="view-cell-value-icon material-icons" style={{verticalAlign: 'middle'}}>monitor_weight</span>
                <span>{numberFormat(value/1000)} (kg)</span> 
              </div>: null;
            },
            isOptions: false,
          },
          {
            code: 'isPaid',
            dataType: EnumDataType.Boolean,
            isOptions: false,
            icon: 'paid',
            cellCss: {
              flex: 'auto',
              textAlign: 'right',
              justifyContent: 'end'
            }
          }
        ]
      ]
    },
    bottom: [
      [
        {
          code: 'japanTrackingCode',
          dataType: EnumDataType.Text,
          isOptions: false,
        },
        {
          code: 'createdAt',
          dataType: EnumDataType.Date,
          isOptions: false,
          icon: 'calendar_month',
          cellCss: {
            display: 'flex',
            color: '#004b78',
            borderRadius: '5px',
            flex: 'auto',
            textAlign: 'right',
            justifyContent: 'end'
          }
        }
      ],
      [
        {
          code: 'receiverName',
          icon: 'call_received',
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            flex: 'auto'
          }
        },
        {
          code: 'receiverPhoneNumber',
          icon: "phone",
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            margin: '0 5px',
          }
        },
      ],
      [
        {
          code: 'receiverAddress',
          icon: 'location_on',
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            margin: '0 5px',
          }
        },
      ]
     
    ],

    actions: (item: ShipOrderType) =>  item.progressStatus == EnumShipOrderProgressStatus.Waiting? [
      {
        icon: 'mode_edit',
        actionType: EnumAction.Edit,
        action: () => onEdit(item.shipOrderId),
        buttonClass: 'info600',
        align: '',
      },
      // {
      //   icon: 'print',
      //   actionType: EnumAction.View,
      //   action: () => onPrint(item),
      //   buttonClass: 'info200',
      //   align: '',
      // },
      {
        icon: 'delete',
        actionType: EnumAction.Delete,
        action: () => onDelete(item.shipOrderId),
        buttonClass: 'info100',
        align: '',
      }
    ] : [
      // {
      //   icon: 'print',
      //   actionType: EnumAction.View,
      //   action: () => onPrint(item),
      //   buttonClass: 'info200',
      //   align: '',
      // },
    ]
  };

  const tableDisplay = {
    header: [
      {
        code: 'images[0]?.thumbUrl',
        dataType: EnumDataType.Image,
        isOptions: false,
        title: 'Ảnh',
      },
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày tạo đơn',
      },
      {
        code: 'billCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã vận đơn',
      },
      {
        code: 'japanTrackingCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã vận đơn JP',
      },
      {
        code: 'transportType',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Hình thức',
        options: TransportType,
      },
      {
        code: 'weight',
        dataType: EnumDataType.Ratio,
        isOptions: false,
        ratio: 1000,
        title: 'Khối lượng (kg)',
      },
      {
        code: 'description',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mô tả hàng hóa',
      },
      {
        code: 'customerNote',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Ghi chú gửi',
      },
      {
        code: 'isPaid',
        dataType: EnumDataType.Boolean,
        isOptions: false,
        title: 'Thanh toán',
      },
      {
        code: 'progressStatus',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Trạng thái',
        options: ShipOrderProgressStatusList,
      },
      {
        code: 'receiverName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Người nhận',
      },
      {
        code: 'receiverPhoneNumber',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'SĐT người nhận',
      },
      {
        code: 'receiverAddress',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ người nhận',
      },
      {
        code: 'vietnamTrackingCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã vận đơn Việt Nam',
      },
    ],
    actions: (item: ShipOrderType) => item.progressStatus == EnumShipOrderProgressStatus.Waiting? [
      {
        icon: 'mode_edit',
        actionType: EnumAction.Edit,
        action: () => onEdit(item.shipOrderId),
        buttonClass: 'info600',
        align: '',
      },
      // {
      //   icon: 'print',
      //   actionType: EnumAction.View,
      //   action: () => onPrint(item),
      //   buttonClass: 'info200',
      //   align: '',
      // },
      {
        icon: 'delete',
        actionType: EnumAction.Delete,
        action: () => onDelete(item.shipOrderId),
        buttonClass: 'info100',
        align: '',
      }
    ] : [
      // {
      //   icon: 'print',
      //   actionType: EnumAction.View,
      //   action: () => onPrint(item),
      //   buttonClass: 'info200',
      //   align: '',
      // },
    ]
  };

  //Function
  const getMyShipOrder = useGetMyShipOrder();
  // const printShipOrder = usePrintShipOrder();
  const deleteShipOrder = useDeleteShipOrder();

  const onDetail = (shipOrderId: number) => {
    navigate(`/ship-order/${shipOrderId}/${EnumViewType.View}`);
  };

  //useEffect
  useEffect(() => {
    getMyShipOrder(keyword, page, size)
      .then((data) => {
        setShipOrderList(data.items);
        setCount(data.count);

      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getMyShipOrder, keyword, page, size, reloadFlag]);


  // const onPrint = (item: ShipOrderType) => {
  //   printShipOrder(item.shipOrderId)
  //     .then((res) => {
  //       printedModal.handlePresent(
  //         {
  //           values: [res],
  //         },
  //         'IN ĐƠN VẬN CHUYỂN',
  //       );
  //     })
  //     .catch((error) => {
  //       addPopup({
  //         error: {
  //           title: 'Đã có lỗi xảy ra',
  //           message: error.errorMessage,
  //         },
  //       });
  //     });
  // };

  //End of component
  const onEdit = (shipOrderId: number) => {
    detailModal.handlePresent(
      {
        shipOrderId: shipOrderId,
        isDisable: false,
        postProcess: (data: ShipOrderType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        },
      },
      'THAY ĐỔI'
    );
  };

  const onAdd = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: (data: ShipOrderType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        },
      },
      'THÊM MỚI'
    );
  };

  const onDelete = (shipOrderId: number) => {
    const onConfirm = () => {
      deleteShipOrder(shipOrderId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa đơn vận chuyển thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa đơn vận chuyển này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA ĐƠN VẬN CHUYỂN',
    );
  }

  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.Add,
      buttonClass: 'info order-tool-btn',
      action: onAdd,
      align: 'center',
    }
  ];

  return (
    <>
      <ToolBar
        toolBarKey='JVSSHIPORDER'
        toolbarName='DANH SÁCH ĐƠN VẬN CHUYỂN'
        width={'100%'}
        listRightButton={listButton}
        backgroundColor={'#ebe9e9'}
        count={count}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
      />

      {window.innerWidth > 600? <Table
        display={tableDisplay}
        isInput={false}
        data={shipOrderList}
        onDoubleClick={(item) => onDetail(item.shipOrderId)}
        isNo={true}
      /> : <ListView
        display={display}
        isInput={false}
        data={shipOrderList}
        onDoubleClick={(item) => onDetail(item.shipOrderId)}
        isNo={true}
      />}
    </>
  );
};

export default ShipOrderList;
