import { Configuration } from './contexts/ConfigProvider/ConfigProvider';

const config: Configuration = {
  backendUrl: 'https://be.jvscorp.jp/',
  backendWs: 'wss://be.jvscorp.jp/scws',
  clientId: '413172745752-hsjvha0s569b467312i3ah69i5k3hrea.apps.googleusercontent.com',
  translateUrl: 'https://translate.googleapis.com/translate_a/',
  defaultAvatar: 'https://rs.jvscorp.jp/images/fcm.png',
};

export default config;
