export const numberFormat = (money: number | undefined) => {
  if(money == undefined) return "";
  return money.toLocaleString();
};

export const number2vnmoney = (money: number | undefined) => {
  if(money == undefined) return "";
  return money.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'});
};

export const number2jpmoney = (money: number | undefined) => {
  if(money == undefined) return "";
  return money.toLocaleString('ja-JP', {style : 'currency', currency : 'JPY'});
};
