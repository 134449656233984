import React from 'react';
import useProfile from 'src/hooks/useProfile';
import { useRemoveProfileInfo } from 'src/state/application/hooks';
import './Account.css';
import { useNavigate } from 'react-router-dom';

const Account: React.FC = () => {
  //Value
  const profile = useProfile();
  const navigate = useNavigate();

  //Function
  const removeProfileInfo = useRemoveProfileInfo();
  const onLogout = () => {
    const url = '/';
    removeProfileInfo();
    navigate(url);
  };

  //Component
  const left = () => {
    return (
      <div className="account-detail-left">
        <div className="account-detail-title">THÔNG TIN TÀI KHOẢN</div>
        <div className="account-detail-component btn">
          <div>
            <span style={{ fontWeight: 500 }}>Họ tên</span>: {profile.info.fullName}
          </div>
        </div>
        <div className="account-detail-component btn">
          <div>
            <span style={{ fontWeight: 500 }}>Email</span>: {profile.info.email}
          </div>
        </div>
      </div>
    );
  };

  const logoutBtn = () => {
    return (
      <div className="account-btn" onClick={onLogout}>
        <div>
          <i className="fas fa-sign-out-alt" style={{ color: '#ed4d2d', width: 25 }}></i>
          <span style={{ marginLeft: 10 }}>Đăng xuất</span>
        </div>
        <div>
          <i className="fas fa-angle-right" style={{ color: 'gray' }}></i>
        </div>
      </div>
    );
  };

  const right = () => {
    return (
      <div className="account-detail-right">
        <div
          className="account-btn"
          onClick={() => {
            const url = '/account/purchase-account';
            navigate(url);
            window.scrollTo(0, 0);
          }}
        >
          <div>
            <i className="fas fa-wallet" style={{ color: '#ed4d2d', width: 25 }}></i>
            <span style={{ marginLeft: 10 }}>Tài khoản thanh toán</span>
          </div>
          <div>
            <i className="fas fa-angle-right" style={{ color: 'gray' }}></i>
          </div>
        </div>
        <div
          className="account-btn"
          onClick={() => {
            const url = '/account/address';
            navigate(url);
            window.scrollTo(0, 0);
          }}
        >
          <div>
            <i className="fas fa-map-marker" style={{ color: '#0090ed', width: 25 }}></i>
            <span style={{ marginLeft: 10 }}>Địa chỉ nhận hàng</span>
          </div>
          <div>
            <i className="fas fa-angle-right" style={{ color: 'gray' }}></i>
          </div>
        </div>
        {logoutBtn()}
      </div>
    );
  };

  //Main
  return (
    <div className="account-container">
      <div className="account-detail">
        {left()}
        {right()}
      </div>
    </div>
  );
};

export default Account;
