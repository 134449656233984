import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import Popups from './components/Popups';
import theme from './theme';
import store from './state';
import { Provider } from 'react-redux';
import { SmartCardProvider } from './contexts/SmartCardProvider/SmartCardProvider';
import SlideBarProvider from './contexts/SlideBar';
import ModalsProvider from './contexts/Modals';
import AppRouters from './contexts/AppRouters';
import Chat from './components/Chat/Chat';
import HeaderBar from './contexts/HeaderBar';
import NavComponent from './components/NavComponent/NavComponent';

const App: React.FC = () => {
  React.useEffect(() => {
    const version = localStorage.getItem('JVSORDER_VERSION');
    fetch('/version.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data: { version: string }) {
        if (data && data.version) {
          if (!version) {
            localStorage.setItem('JVSORDER_VERSION', data.version);
          } else {
            if (version != data.version) {
              try {
                if ('caches' in window) {
                  caches.keys().then((names) => {
                    names.forEach(async (name) => {
                      await caches.delete(name);
                    });
                  });
                }
              } catch (e) {
                console.log(e);
              }
              localStorage.setItem('JVSORDER_VERSION', data.version);
              window.location.reload();
            }
          }
        }
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SmartCardProvider>
          <SlideBarProvider>
            <ModalsProvider>
              <Popups />
              <BrowserRouter>
                <AppWrapper>
                  <HeaderBar />
                  <NavComponent />
                  <AppContent>
                    <AppRouters />
                  </AppContent>
                </AppWrapper>
              </BrowserRouter>
              <Chat key={'chat-component'} />
            </ModalsProvider>
          </SlideBarProvider>
        </SmartCardProvider>
      </Provider>
    </ThemeProvider>
  );
};


export default App;
const AppWrapper = styled.div`
`;

const AppContent = styled.div`
  min-height: 60vh;
`;
