import React from 'react';
import { ReceiverInfoType } from 'src/api/models';
import './ReceiverInfoComponent.css';
import { useNavigate } from 'react-router-dom';

interface IReceiverInfoComponent {
  data: ReceiverInfoType;
  index: number;
}

const ReceiverInfoComponent: React.FC<IReceiverInfoComponent> = (props) => {
  //Value
  const data = props.data;
  const navigate = useNavigate();

  //Main
  return (
    <div
      className={`col-lg-11 col-12 receiver-info_component_container ${
        props.index % 2 == 0 ? 'even' : ''
      }`}
    >
      <div className="re-info-wrapper">
        <div className="receiver-info_component_child">
          {data.fullname} | {data.phoneNumber}
        </div>
        <div className="receiver-info_component_child">{data.address4}</div>
        <div className="receiver-info_component_child">
          {data.address3 && data.address3 != '' ? `${data.address3}, ` : ''}
          {data.address2 && data.address2 != '' ? `${data.address2}, ` : ''}
          {data.address1 && data.address1 != '' ? data.address1 : ''}
        </div>
        <div className="receiver-info_component_child">
          {data.facebook ? `Fb: ${data.facebook} | ` : ''}
          {data.zalo ? `Zalo: ${data.zalo}` : ''}
        </div>
      </div>
      <div className="choose-re-info_edit-btn">
        <button
          className="dashboard_button_child re-info_cpn_btn"
          onClick={() => {
            navigate(`/account/address/edit?tid=${data.receiverInfoId}`);
          }}
        >
          Sửa
        </button>
      </div>
    </div>
  );
};

export default ReceiverInfoComponent;
