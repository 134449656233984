import React, { useState, useEffect } from 'react';
import { PurchaseAccountType } from 'src/api/models';
import { useGetMyPurchaseAccount } from 'src/api/purchaseAccountApi';
import PurchaseAccountComponent from 'src/components/PurchaseAccountComponent/PurchaseAccountComponent';
import useProfile from 'src/hooks/useProfile';
import './PurchaseAccountCommon.css';

const PurchaseAccountCommon: React.FC = () => {
  //Value
  const profile = useProfile();

  //State
  const [purchaseAccountList, setPurchaseAccountList] = useState<PurchaseAccountType[]>([]);
  //End of state

  //Function
  const getMyPurchaseAccount = useGetMyPurchaseAccount();

  //Component
  const purchaseAccountListComponent = () => {
    return (
      <div className="row justify-content-center p-2 mt-2 mr-2 ml-2">
        {purchaseAccountList.map((value, index) => {
          return <PurchaseAccountComponent data={value} index={index} />;
        })}
      </div>
    );
  };

  //useEffect
  useEffect(() => {
    getMyPurchaseAccount()
      .then((data) => {
        setPurchaseAccountList(data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getMyPurchaseAccount]);

  //Main
  return (
    <div className="container-fluid dashboard-container p-0">
      <div className="dashboard_title">Danh sách tài khoản đã cấp</div>
      {purchaseAccountListComponent()}
    </div>
  );
};

export default PurchaseAccountCommon;
