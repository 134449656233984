import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import useProfile from "src/hooks/useProfile";

import ConfirmRegister from 'src/views/AuthScreen/ConfirmRegister/ConfirmRegister';
import ForgotPassword from 'src/views/AuthScreen/ForgotPassword/ForgotPassword';
import Register from 'src/views/AuthScreen/Register/Register';
import ConfirmForgotPassword from 'src/views/AuthScreen/ConfirmForgotPassword/ConfirmForgotPassword';
import Verify from 'src/views/AuthScreen/ChangePassword/ChangePassword';
import Login from 'src/views/AuthScreen/Login/Login';
import Account from "src/views/Account/Account";
import StaticPage from "src/views/StaticPage/StaticPage";
import BaseHome from "src/views/BaseHome/BaseHome";
import Dashboard from "src/views/Dashboard/Dashboard";
import Tracking from "src/views/Tracking/Tracking";
import Wallet from "src/views/Wallet/Wallet";
import AccountAddressCommon from "src/views/Account/AccountAddress/AccountAddressCommon/AccountAddressCommon";
import EditAccountAddress from "src/views/Account/AccountAddress/EditAccountAddress/EditAccountAddress";
import PurchaseAccountCommon from "src/views/Account/PurchaseAccount/PurchaseAccountCommon/PurchaseAccountCommon";
import EditPurchaseAccount from "src/views/Account/PurchaseAccount/EditPurchaseAccount/EditPurchaseAccount";
import ProductOrderDetail from "src/views/ProductOrder/ProductOrderDetail/ProductOrderDetail";
import ShipOrder from "src/views/ShipOrder";
import ProductOrder from "src/views/ProductOrder";
import ShipOrderDetail from "src/views/ShipOrder/ShipOrderDetail/ShipOrderDetail";
import Payment from "src/views/Payment/Payment";
import PaymentDetail from "src/views/Payment/PaymentDetail/PaymentDetail";

const AppRouters: React.FC = () => {
  //Value
  const profile = useProfile();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!profile && !location.pathname.includes("/auth") && !location.pathname.includes("/tracking")  && !location.pathname.includes("payment/")) navigate('/');
  }, [location.pathname, navigate, profile]);

  //Main
  return (
    <Routes>
      <Route path="/auth/confirm-register" element={<ConfirmRegister />} />
      <Route path="/auth/forgot-password" element={<ForgotPassword />} />
      <Route path="/auth/register" element={<Register />} />
      <Route path="/auth/confirm-forgot-password" element={<ConfirmForgotPassword />} />
      <Route path="/auth/change-password" element={<Verify />} />
      <Route path="/auth" element={<Login />} />

      <Route path="/account" element={<Account />} />
      <Route path="/account/address"  element={<AccountAddressCommon />} />
      <Route path="/account/address/edit" element={<EditAccountAddress />} />
      <Route path="/account/purchase-account"  element={<PurchaseAccountCommon />} />
      <Route path="/account/purchase-account/edit" element={<EditPurchaseAccount />} />
      <Route path="/static-page/:key" element={<StaticPage />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/product-order" element={<ProductOrder />} />
      <Route path="/product-order/:productOrderId/:type" element={<ProductOrderDetail />} />

      <Route path="/tracking" element={<Tracking />} />
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/ship-order" element={<ShipOrder/>} />
      <Route path="/ship-order/:shipOrderId/:type" element={<ShipOrderDetail/>} />
      <Route path="/" element={<BaseHome />} />

      <Route path="/payment" element={<Payment/>} />
      <Route path="/payment/:code" element={<PaymentDetail />} />
    </Routes>
  );
};

export default AppRouters;
