import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useGetMyProcessedProductOrder } from 'src/api/productOrdrerApi';
import { useGetMyPurchaseAccount } from 'src/api/purchaseAccountApi';
import { useGetMyReceiverInfo } from 'src/api/receiverInfoApi';
import { useGetProcessedShipOrder } from 'src/api/shipOrderApi';
import Images from 'src/assets/img';
import './Dashboard.css';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  const [processProductOrderCount, setProcessProductOrderCount] = useState(0);
  const [processShipOrderCount, setProcessShipOrderCount] = useState(0);

  const [receiverInfoCount, setReceiverInfoCount] = useState(0);
  const [purchaseAccountCount, setPurchaseAccountCount] = useState(0);
  //End of state

  //Function
  const getMyReceiverInfo = useGetMyReceiverInfo();
  const getMyProcessedShipOrder = useGetProcessedShipOrder();
  const getMyProcessedProductOrder = useGetMyProcessedProductOrder();
  const getMyPurchaseAccount = useGetMyPurchaseAccount();

  const onClickLink = (link: string, blank: boolean) => {
    if (blank) {
      window.open(link, '_blank');
    } else {
      navigate(link);
    }
  };

  //Component
  const productOrderComponent = () => {
    return (
      <div
        className="col-lg-4 col-12 dashboard_component product"
        onClick={() => {
          onClickLink('/product-order/list', false);
          window.scrollTo(0, 0);
        }}
      >
        <div className="dashboard_component_child">
          <i className="fas fa-laptop"></i>
          <div>Order chờ xử lý</div>
        </div>
        <div className="dashboard_component_child last">
          <span>{processProductOrderCount}</span>
          <div>Đơn hàng</div>
        </div>
      </div>
    );
  };

  const shipOrderComponent = () => {
    return (
      <div
        className="col-lg-4 col-12 dashboard_component ship"
        onClick={() => {
          onClickLink('/ship-order', false);
          window.scrollTo(0, 0);
        }}
      >
        <div className="dashboard_component_child">
          <i className="fas fa-shipping-fast"></i>
          <div>Vận chuyển chờ xử lý</div>
        </div>
        <div className="dashboard_component_child last">
          <span>{processShipOrderCount}</span>
          <div>Đơn hàng</div>
        </div>
      </div>
    );
  };

  const addressComponent = () => {
    return (
      <div
        className="col-lg-4 col-12 dashboard_component address"
        onClick={() => {
          onClickLink('/account/address', false);
          window.scrollTo(0, 0);
        }}
      >
        <div className="dashboard_component_child">
          <i className="fas fa-map-marker"></i>
          <div>Địa chỉ nhận hàng</div>
        </div>
        <div className="dashboard_component_child last">
          <span>{receiverInfoCount}</span>
          <div>Địa chỉ</div>
        </div>
      </div>
    );
  };

  const purchaseAccountComponent = () => {
    return (
      <div
        className="col-lg-4 col-12 dashboard_component purchase-account"
        onClick={() => {
          onClickLink('/account/purchase-account', false);
          window.scrollTo(0, 0);
        }}
      >
        <div className="dashboard_component_child">
          <i className="fas fa-file-invoice"></i>
          <div>Tài khoản đã cấp</div>
        </div>
        <div className="dashboard_component_child last">
          <span>{purchaseAccountCount}</span>
          <div>Tài khoản</div>
        </div>
      </div>
    );
  };


  //useEffect
  useEffect(() => {
    getMyReceiverInfo()
      .then((data) => {
        setReceiverInfoCount(data.length);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getMyReceiverInfo]);



  useEffect(() => {
    getMyProcessedProductOrder()
      .then((data) => {
        setProcessProductOrderCount(data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getMyProcessedProductOrder]);

  useEffect(() => {
    getMyProcessedShipOrder()
      .then((data) => {
        setProcessShipOrderCount(data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getMyProcessedShipOrder]);

  useEffect(() => {
    getMyPurchaseAccount()
      .then((data) => {
        setPurchaseAccountCount(data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getMyPurchaseAccount]);

  //Main
  return (
    <div className="container-fluid dashboard-container p-0">
      <div className="container-fluid dashboard-container">
        <div className="dashboard_title">Dashboard</div>
        <div className="row justify-content-center m-2">
          <div className="col-1 d-none d-lg-flex"></div>
          {/* {productOrderComponent()} */}
          <div className="col-1 d-none d-lg-flex"></div>
          {shipOrderComponent()}
          <div className="col-1 d-none d-lg-flex"></div>
          {addressComponent()}
          <div className="col-1 d-none d-lg-flex"></div>
          {purchaseAccountComponent()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
