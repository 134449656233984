import React, { useEffect, useState } from 'react';
import { useGetTranslateToJp } from 'src/api/googleTranslateApi';
import { EnumSearchOption } from 'src/api/models';
import Images from 'src/assets/img';
import './SearchAndTrackingBaseHome.css';
import { useNavigate } from 'react-router-dom';
import useModal from 'src/hooks/useModal';
import QRCodeReader from '../QRCodeReader/QRCodeReader';

const SearchAndTrackingBaseHome: React.FC = () => {
  //CONST
  const BASE_YAHOO_AUCTION_SEARCH_URL =
    'https://auctions.yahoo.co.jp/search/search?auccat=&tab_ex=commerce&ei=utf-8&aq=-1&oq=&sc_i=&fr=auc_top&x=0&y=0&p=';
  const BASE_RAKUTEN_SEARCH_URL = 'https://search.rakuten.co.jp/search/mall/';
  const BASE_AMAZON_SEARCH_URL = 'https://www.amazon.co.jp/s?k=';
  const BASE_MERCARI_SEARCH_URL = 'https://jp.mercari.com/search?keyword=';
  const BASE_YAHOO_SHOPPING_SEARCH_URL = 'https://shopping.yahoo.co.jp/search?p=';
  const BASE_PAYPAY_SEARCH_URL = 'https://paypaymall.yahoo.co.jp/search?p=';

  //Value
  const navigate = useNavigate();

  //State
  const [searchFocus, setSearchFocus] = useState(true);
  const [isShowSearchOpion, setIsShowSearchOpion] = useState(false);
  const [focusSearchOption, setFocusSearchOption] = useState<EnumSearchOption>(
    EnumSearchOption.rakuten,
  );
  const [keyword, setKeyword] = useState<string>('');
  const [trackingCode, setTrackingCode] = useState<string>('');
  const qrReaderModal = useModal(QRCodeReader);

  //Function
  const getTranslate = useGetTranslateToJp();
  const clickBtnSearchOption = () => {
    setIsShowSearchOpion(!isShowSearchOpion);
  };

  const onSearch = () => {
    if (keyword && keyword != '') {
      getTranslate(keyword).then((r) => {
        const q: string = r[0][0][0];
        if (focusSearchOption == EnumSearchOption.yahoo_auction) {
          const link = BASE_YAHOO_AUCTION_SEARCH_URL + q;
          window.open(link, '_blank');
        } else if (focusSearchOption == EnumSearchOption.rakuten) {
          const link = BASE_RAKUTEN_SEARCH_URL + q;
          window.open(link, '_blank');
        } else if (focusSearchOption == EnumSearchOption.amazon) {
          const link = BASE_AMAZON_SEARCH_URL + q;
          window.open(link, '_blank');
        } else if (focusSearchOption == EnumSearchOption.mercari) {
          const link = BASE_MERCARI_SEARCH_URL + q;
          window.open(link, '_blank');
        } else if (focusSearchOption == EnumSearchOption.yahoo_shopping) {
          const link = BASE_YAHOO_SHOPPING_SEARCH_URL + q;
          window.open(link, '_blank');
        } else if (focusSearchOption == EnumSearchOption.paypay) {
          const link = BASE_PAYPAY_SEARCH_URL + q;
          window.open(link, '_blank');
        }
      });
    }
  };

  const onTracking = () => {
    if (trackingCode && trackingCode != '') {
      const url = `/tracking?trackingCode=${trackingCode}`;
      navigate(url);
    }
  };

  useEffect(() => {
    const handleClick = (event) => {
      const wrapper = document.getElementById('search-wrapper');
      const searchBaseBtn = document.getElementById('search_base-btn');
      if (searchBaseBtn) {
        if (searchBaseBtn?.contains(event.target)) {
          console.log('click-btn-search-option');
        } else if (!wrapper?.contains(event.target)) {
          setIsShowSearchOpion(false);
        }
      }
    };

    document.addEventListener('click', handleClick, false);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  //Component
  const trackingAndSearchBtn = () => {
    return (
      <div className="base-home_search_btn">
        <div
          className={`base-home_search_title search-title ${searchFocus ? 'true' : ''}`}
          onClick={() => {
            if (!searchFocus) {
              setSearchFocus(true);
            }
          }}
        >
          <i className="fas fa-search"></i>TÌM KIẾM
        </div>
        <div
          className={`base-home_search_title tracking-title ${!searchFocus ? 'true' : ''}`}
          onClick={() => {
            if (searchFocus) {
              setSearchFocus(false);
            }
          }}
        >
          <i className="fas fa-plane-departure"></i>TRACKING
        </div>
      </div>
    );
  };

  const searchOptionComponent = () => {
    return (
      <div className="search-option-container">
        <div
          className="search-option_main-btn"
          onClick={clickBtnSearchOption}
          id="search_base-btn"
        >
          {focusSearchOption == EnumSearchOption.yahoo_auction ? (
            <div className="search-option_base-display">
              <img
                className="base-home_ecommerce-list-child-img"
                src={Images.yahoo_auction_icon}
                alt=""
              />
              Yahoo!Auction
            </div>
          ) : focusSearchOption == EnumSearchOption.rakuten ? (
            <div className="search-option_base-display">
              <img
                className="base-home_ecommerce-list-child-img"
                src={Images.rakuten_icon}
                alt=""
              />
              Rakuten
            </div>
          ) : focusSearchOption == EnumSearchOption.amazon ? (
            <div className="search-option_base-display">
              <img
                className="base-home_ecommerce-list-child-img"
                src={Images.amazon_icon}
                alt=""
              />
              Amazon
            </div>
          ) : focusSearchOption == EnumSearchOption.mercari ? (
            <div className="search-option_base-display">
              <img
                className="base-home_ecommerce-list-child-img"
                src={Images.mercari_icon}
                alt=""
              />
              Mercari
            </div>
          ) : focusSearchOption == EnumSearchOption.yahoo_shopping ? (
            <div className="search-option_base-display">
              <img
                className="base-home_ecommerce-list-child-img"
                src={Images.yahoo_shopping_icon}
                alt=""
              />
              Yahoo!Shopping
            </div>
          ) : focusSearchOption == EnumSearchOption.paypay ? (
            <div className="search-option_base-display">
              <img
                className="base-home_ecommerce-list-child-img"
                src={Images.paypay_icon}
                alt=""
              />
              Paypay
            </div>
          ) : null}
          <div>
            <i className="fas fa-sort"></i>
          </div>
        </div>
        {isShowSearchOpion ? (
          <div className="search-option_element-container" id="search-wrapper">
            <div
              className={`search-option_base-display elm ${
                focusSearchOption == EnumSearchOption.yahoo_auction ? 'last' : ''
              }`}
              onClick={() => {
                setFocusSearchOption(EnumSearchOption.yahoo_auction);
                setIsShowSearchOpion(false);
              }}
            >
              <img
                className="base-home_ecommerce-list-child-img"
                src={Images.yahoo_auction_icon}
                alt=""
              />
              Yahoo!Auction
            </div>
            <div
              className={`search-option_base-display elm ${
                focusSearchOption == EnumSearchOption.rakuten ? 'last' : ''
              }`}
              onClick={() => {
                setFocusSearchOption(EnumSearchOption.rakuten);
                setIsShowSearchOpion(false);
              }}
            >
              <img
                className="base-home_ecommerce-list-child-img"
                src={Images.rakuten_icon}
                alt=""
              />
              Rakuten
            </div>
            <div
              className={`search-option_base-display elm ${
                focusSearchOption == EnumSearchOption.amazon ? 'last' : ''
              }`}
              onClick={() => {
                setFocusSearchOption(EnumSearchOption.amazon);
                setIsShowSearchOpion(false);
              }}
            >
              <img
                className="base-home_ecommerce-list-child-img"
                src={Images.amazon_icon}
                alt=""
              />
              Amazon
            </div>
            <div
              className={`search-option_base-display elm ${
                focusSearchOption == EnumSearchOption.mercari ? 'last' : ''
              }`}
              onClick={() => {
                setFocusSearchOption(EnumSearchOption.mercari);
                setIsShowSearchOpion(false);
              }}
            >
              <img
                className="base-home_ecommerce-list-child-img"
                src={Images.mercari_icon}
                alt=""
              />
              Mercari
            </div>
            <div
              className={`search-option_base-display elm ${
                focusSearchOption == EnumSearchOption.yahoo_shopping ? 'last' : ''
              }`}
              onClick={() => {
                setFocusSearchOption(EnumSearchOption.yahoo_shopping);
                setIsShowSearchOpion(false);
              }}
            >
              <img
                className="base-home_ecommerce-list-child-img"
                src={Images.yahoo_shopping_icon}
                alt=""
              />
              Yahoo!Shopping
            </div>
            <div
              className={`search-option_base-display elm ${
                focusSearchOption == EnumSearchOption.paypay ? 'last' : ''
              }`}
              onClick={() => {
                setFocusSearchOption(EnumSearchOption.paypay);
                setIsShowSearchOpion(false);
              }}
            >
              <img
                className="base-home_ecommerce-list-child-img"
                src={Images.paypay_icon}
                alt=""
              />
              Paypay
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const searchComponent = () => {
    return (
      <div className="base-home_search_content">
        <div className="base-home_search-child first">{searchOptionComponent()}</div>
        <div className="base-home_search-full">
          <input
            className="base-home_input"
            value={keyword}
            placeholder={'Từ khóa (ví dụ ラップトップ)'}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
        </div>
        <div
          className="base-home_search-child last"
          onClick={(e) => {
            e.preventDefault();
            onSearch();
          }}
        >
          <button>Tìm kiếm</button>
        </div>
      </div>
    );
  };

  const trackingComponent = () => {
    return (
      <div className="base-home_search_content">
        <div className="base-home_search-full">
          <div className="tracking-scanner-qr">
            <span className="material-icons" onClick={() => {
              qrReaderModal.handlePresent({
                readQR: (value: string) => {
                  setTrackingCode(trackingCode.trim().length > 0? `${trackingCode.trim()},${value}` : value)
                  qrReaderModal.handleDismiss();
                }
              }, "QUÉT MÃ VẬN CHUYỂN")
            }}>qr_code</span>
            <input
              type="text"
              className="base-home_input"
              value={trackingCode}
              placeholder="Mã vận đơn (phân tách bằng dấu ,)"
              onChange={(e) => {
                setTrackingCode(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          className="base-home_search-child last"
          onClick={(e) => {
            e.preventDefault();
            onTracking();
          }}
        >
          <button>Tìm kiếm</button>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid mt-lg-5 mt-4">
      <div className="row justify-content-center">
        <div className="col-lg-9 col-11 base-home_search_container">
          {trackingAndSearchBtn()}
          {searchFocus ? searchComponent() : trackingComponent()}
        </div>
      </div>
    </div>
  );
};

export default SearchAndTrackingBaseHome;
