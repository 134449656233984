import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './NavComponent.css';
import useProfile from 'src/hooks/useProfile';

const NavComponent: React.FC = () => {

  const profile = useProfile();
  const location = useLocation();

  const [isHidden, setHidden] = useState(false);

  useEffect(() => {
    setHidden(location.pathname.includes("/auth") || location.pathname.includes("payment/"));
  }, [location]);

  return (
    !isHidden && profile && <div className="nav-component_container">
      <NavLink to={'/dashboard'} className={location.pathname.includes('dashboard')? 'focus' : ''}>
        <div className="nav-component_child">
          <i className="fas fa-tachometer-alt"></i>
          <div className='nav-name'>Dashboard</div>
        </div>
      </NavLink>
      {/* <NavLink to={'/product-order'} className={location.pathname.includes('product-order')? 'focus' : ''}>
        <div className="nav-component_child">
          <i className="fas fa-laptop"></i>
          <div className='nav-name'>Mua hàng hộ</div>
        </div>
      </NavLink> */}
      <NavLink to={'/ship-order'} className={location.pathname.includes('ship-order')? 'focus' : ''}>
        <div className="nav-component_child">
          <i className="fas fa-shipping-fast"></i>
          <div className='nav-name'>Vận chuyển ký gửi</div>
        </div>
      </NavLink>
      <NavLink to={'/tracking'} className={location.pathname.includes('tracking')? 'focus' : ''}>
        <div className="nav-component_child">
          <i className="fas fa-plane-departure"></i>
          <div className='nav-name'>Tracking</div>
        </div>
      </NavLink>
      {/* <NavLink to={'/wallet'} className={location.pathname.includes('wallet')? 'focus' : ''}>
        <div className="nav-component_child">
          <i className="fas fa-wallet"></i>
          <div className='nav-name'>Quản lý tài chính</div>
        </div>
      </NavLink> */}
      <NavLink to={'/payment'} className={location.pathname.includes('payment')? 'focus' : ''}>
        <div className="nav-component_child">
          <i className="fas fa-money-check"></i>
          <div className='nav-name'>Hóa đơn thanh toán</div>
        </div>
      </NavLink>
      <NavLink to={'/account'} className={location.pathname.includes('account')? 'focus' : ''}>
        <div className="nav-component_child">
          <i className="fas fa-user-circle"></i>
          <div className='nav-name'>Tài khoản</div>
        </div>
      </NavLink>
    </div>
  );
};

export default NavComponent;
