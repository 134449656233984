/************************************/
/********                    ********/
/******      Product Order   ********/
/******   Writen by HuyLV      ****/
/********                   ********/
/***********************************/

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { ImageType, PageData, ProductOrderType } from './models';

export const useGetProductOrderById = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/product-order/${productOrderId}`,
        method: 'get',
      }) as Promise<ProductOrderType>;
    },
    [fetch],
  );
};

export const useGetMyProductOrderByIds = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrderIds: number[]) => {
      return fetch({
        url: `sccore/rest-api/product-order/me/ids`,
        method: 'post',
        data: productOrderIds
      }) as Promise<ProductOrderType[]>;
    },
    [fetch],
  );
};

export const useDeleteProductOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/product-order/${productOrderId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePostProductOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrder: ProductOrderType) => {
      return fetch({
        url: `sccore/rest-api/product-order`,
        method: 'post',
        data: productOrder,
      }) as Promise<ProductOrderType>;
    },
    [fetch],
  );
};

export const usePutProductOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrder: ProductOrderType) => {
      return fetch({
        url: `sccore/rest-api/product-order`,
        method: 'put',
        data: productOrder,
      }) as Promise<ProductOrderType>;
    },
    [fetch],
  );
};

export const useGetMyProductOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number) => {
      return fetch({
        url: `sccore/rest-api/product-order/me?keyword=${keyword}&page=${page}&size=${size}`,
        method: 'get',
      }) as Promise<PageData<ProductOrderType>>;
    },
    [fetch],
  );
};

export const useGetMyProcessedProductOrder = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/product-order/me?progressStatus=1`,
      method: 'get',
    }) as Promise<PageData<ProductOrderType>>;
  }, [fetch]);
};

export const usePostMyProductOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrder: ProductOrderType) => {
      return fetch({
        url: `sccore/rest-api/product-order/me`,
        method: 'post',
        data: productOrder,
      }) as Promise<ProductOrderType>;
    },
    [fetch],
  );
};

export const usePutMyProductOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrder: ProductOrderType) => {
      return fetch({
        url: `sccore/rest-api/product-order/me`,
        method: 'put',
        data: productOrder,
      }) as Promise<ProductOrderType>;
    },
    [fetch],
  );
};

export const usePostProductOrderImage = () => {
  const fetch = useFetch(false, true);
  return useCallback(
    (data, productOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/product-order/image/${productOrderId}`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};
//End of Product Order
