import { useState } from 'react';
import React from 'react';
import { EnumShipOrderProgressStatus, ShipOrderType, TrackingStatusType, TrackingType } from 'src/api/models';
import { useEffect } from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import './TrackingComponent.css';
import { useGetShipOrderByCode } from 'src/api/shipOrderApi';
import SelectBoxComponent from '../SelectBoxComponent/SelectBoxComponent';
import ImageUpload from '../ImageUpload';
import Input from '../Input';

interface ITrackingComponent {
  trackingCode: string;
}
const TrackingComponent: React.FC<ITrackingComponent> = (props) => {
  //Value
  const trackingCode = props.trackingCode;

  //State
  const [shipOrder, setShipOrder] = useState<ShipOrderType>(null);
  const [errorCode, setErrorCode] = useState<string>(null);

  //Function
  // const getOrderTracking = useGetOrderTracking();
  const getShipOrderByCode = useGetShipOrderByCode();
  const addPopup = useAddPopup();

  const lstStatus = [
    {
      value: EnumShipOrderProgressStatus.Waiting,
      title: 'Đang chờ',
      icon: 'fas fa-pause-circle'
    },
    {
      value: EnumShipOrderProgressStatus.JapanStore,
      title: 'Đến kho JP',
      icon: 'fas fa-box-open'
    },
    {
      value: EnumShipOrderProgressStatus.Flying,
      title: 'Đang bay',
      icon: 'fas fa-fighter-jet'
    },
    {
      value: EnumShipOrderProgressStatus.ThroughCustoms,
      title: 'Chờ thông quan',
      icon: 'fas fa-recycle'
    },
    {
      value: EnumShipOrderProgressStatus.VietNamStore,
      title: 'Đến kho VN',
      icon: 'fas fa-shipping-fast'
    },
    {
      value: EnumShipOrderProgressStatus.Finish,
      title: 'Kết thúc',
      icon: 'fas fa-check-circle'
    },
  ];


  useEffect(() => {
    if (trackingCode !== null && trackingCode !== '') {
      getShipOrderByCode(trackingCode)
        .then((data: ShipOrderType) => {
          setShipOrder(data);
        })
        .catch((error) => {
          addPopup({
            txn: {
              success: false,
              summary: error.errorMessage,
            },
          });
          setErrorCode(error.errorMessage);
          setShipOrder(undefined);
        });
    }
  }, [addPopup, getShipOrderByCode, trackingCode]);

  //Main
  return shipOrder ? (
    <div className="tracking-wrapper">
      <div className="tracking-component-header">Mã vận đơn: {trackingCode}</div>
      <div className='tracking-content'>
        <div className="tracking-status-container">
          <ImageUpload
            images={shipOrder.images}
            showOnlyOne={true}
          />
          <div style={{width: '100%'}}>
            <SelectBoxComponent
              width='100%'
              isDisable={true}
              title={'Trạng thái'}
              value={shipOrder.progressStatus}
              data={lstStatus}
              valueType={'value'}
              titleType={'title'}
            />
            <Input
              width="100%"
              title="Người gửi"
              disabled={true}
              value={shipOrder.senderName}
            />
            <Input
              width='100%'
              title="Tên người nhận"
              disabled={true}
              value={shipOrder.receiverName}
            />
            <Input
              width="100%"
              title="Mã vận chuyển JP"
              disabled={true}
              value={shipOrder.japanTrackingCode}
            />
            <Input
              width="100%"
              title="Mã vận chuyển VN"
              disabled={true}
              value={shipOrder.vietnamTrackingCode}
            />
            <span className='vn-tracking-guide'>Truy cập <span onClick={() => {
              navigator.clipboard.writeText(shipOrder.vietnamTrackingCode).then(() => {
                if(shipOrder.vietnamTrackingCode?.includes('YCBS')) {
                  window.open(`https://giaohangsieuviet.com/tracking/?code=${shipOrder.vietnamTrackingCode??''}`);
                } else {
                  window.open("https://viettelpost.com.vn/tra-cuu-hanh-trinh-don/");
                }
              });
            }}>Link</span> để tra cứu thông tin vận chuyển nội địa</span>
          </div>

        </div>
        <div className="tracking-detail-container">
            {lstStatus.map((status, idx) => (
              <div key={`tracking-detail-child${idx}`} className={`tracking-detail-child ${shipOrder.progressStatus >= status.value? 'active' : ''}`}>
                <i className={status.icon}></i>
                <div>{status.title}</div>
              </div>
            ))}
        </div>

      </div>

    </div>
  ) : errorCode ? (
    <div className="tracking-wrapper">
      <div className="tracking-component-header">Mã vận đơn: {trackingCode}</div>
      <div className="container-fluid tracking-detail-container">LỖI: {errorCode}</div>
    </div>
  ) : (
    <div>Đang tải</div>
  );
};

export default TrackingComponent;
