/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

interface ModalsContext {
  content?: ModalInfo[];
  onPresent: (content: React.FC<any>, props: object, title?: string, isFullView?: boolean, width?: string, height?: string, backdropClick?: boolean, id?: string) => string;
  onDismiss: (id: string) => void;
}

export const Context = createContext<ModalsContext>({
  onPresent: () => '',
  onDismiss: () => {},
});

interface ModalInfo {
  id: string;
  content?: React.FC;
  props: any;
  title?: string;
  isFullView?: boolean;
  backdropClick?: boolean;
  width?: string;
  height?: string;
}

interface ModalsProps {
  children: React.ReactNode
}

const ModalsProvider: React.FC<ModalsProps> = ({ children }) => {
  const [modals, setModals] = useState<ModalInfo[]>([]);
  const [height, setHeigh] = useState<number>(window.innerHeight);

  const present = (modal: ModalInfo) => {
    setModals((oldList) => {
      return [...oldList, modal];
    });
  };


  const handlePresent = useCallback(
    (modalContent: React.FC, props: object, title?: string, isFullView?: boolean, width?: string, height?: string, backdropClick?: boolean, id?: string) => {
      id = id || v4();
      present({ id, content: modalContent, props: {...props, isPopup: true}, title, isFullView, width, height, backdropClick });
      return id;
    },
    [],
  );

  const handleDismiss = useCallback((id: string) => {
    setModals((data) => data.filter((t) => t.id !== id));
  }, []);

  const backdropClick = useCallback(() => {
    if (modals.length === 0) {
      return;
    }
    setModals((data) => data.slice(0, data.length - 1));
  }, [modals]);

  useEffect(() => {
    const onScroll =  (event) => {
      setHeigh(window.innerHeight + window.scrollY);
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    }
  }, [])


  return (
    <Context.Provider
      value={{
        content: modals,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
      }}
    >
      {children}
      {modals?.length > 0 ? <StyledBackGround height={height}/> : null}
      {modals?.map((modal, index: number) => (
        <StyledModalWrapper key={`modal${index}`}>
          <StyledBackdrop onClick={modal.backdropClick ? backdropClick : undefined} height={height} />
          <ModalContent width={modal.width} height={modal.height} isFullView={modal.isFullView}>
            {modal.title ? <HeaderData>
              <label style={{flex: 1}}>{modal.title}</label>
              <span className="material-icons" onClick={() => handleDismiss(modal.id)}>clear</span>
            </HeaderData> : <StyledClearModal className="material-icons" onClick={() => handleDismiss(modal.id)}>clear</StyledClearModal>}
            <modal.content {...modal.props}/>

          </ModalContent>
        </StyledModalWrapper>
      ))}
    </Context.Provider>
  );
};

const StyledClearModal = styled.span`
  position: absolute;
  top: 0px;
  right: 0px;
  color: white;
  background-color: #f13838;
  font-size: 40px;
  border-radius: 8px;
  cursor: pointer;
  z-index: 3;
`;

const HeaderData = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  width: 100%;
  padding: 8px;
  background-color: #256cb8;
  color: white;
  font-weight: 500;
`;

const ModalContent = styled.div<{width?: string, height?: string, isFullView?: boolean}>`
  position: fixed;
  z-index: 150;
  width: ${({width}) => width? width: 'fit-content'};
  height: ${({height}) => height? height: 'fit-content'};
  transition: 1s;
  ${({isFullView}) => !isFullView?
  `max-width: calc(100% - 10px);
  border: 2px solid #256cb8;
  box-shadow: grey 0px 0px 3px 1px;` : ''}
  z-index: 2;
`;



const StyledModalWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

const StyledBackdrop = styled.div<{ height?: number}>`
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${({height}) => height? `height: ${height}px`: ''};
`;

const StyledBackGround = styled.div<{ height?: number}>`
  background-color: #222222;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  ${({height}) => height? `height: ${height}px`: ''};

`;

export default memo(ModalsProvider);
