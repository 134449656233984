import React, { useState, useEffect } from 'react';
import { EnumAction, EnumDataType, EnumProductOrderProgressStatus, EventButton, ProductOrderType } from 'src/api/models';
import { useDeleteProductOrder, useGetMyProductOrder } from 'src/api/productOrdrerApi';
import { useAddPopup } from 'src/state/application/hooks';
import './ProductOrder.css';
import { useNavigate } from 'react-router-dom';
import useModal from 'src/hooks/useModal';
import ProductOrderDetail from './ProductOrderDetail/ProductOrderDetail';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import { ItemDisplayType } from 'src/components/ListView/ItemView/ItemView';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Table from 'src/components/Table/Table';
import ListView from 'src/components/ListView/ListView';

const ProductOrder: React.FC = () => {
  //Value
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [count, setCount] = useState(1);

  const [productOrderList, setProductOrderList] = useState<ProductOrderType[]>([]);
  const detailModal = useModal(ProductOrderDetail);
  const confirmModal = useModal(ConfirmModal);
  const deleteProductOrder = useDeleteProductOrder();
  const [reloadFlag, setReloadFlag] = useState(false);

  const lstStatus = [
    {
      value: EnumProductOrderProgressStatus.Waiting,
      title: 'Chờ duyệt',
      css: { color: '#3a3131' },
    },
    {
      value: EnumProductOrderProgressStatus.Bought,
      title: 'Đã mua',
      css: { color: 'blue' },
    },
    {
      value: EnumProductOrderProgressStatus.JapanStore,
      title: 'Đã về kho JP',
      css: { color: 'green' },
    },
  ];

  const display: ItemDisplayType<ProductOrderType> = {
    header: [
      {
        code: 'productName',
        dataType: EnumDataType.Text,
        isOptions: false,
        cellCss: {
          display: 'flex',
          flex: 'auto'
        }
      },
      {
        code: 'progressStatus',
        dataType: EnumDataType.Text,
        isOptions: true,
        options: lstStatus,
        cellCss: {
          display: 'flex',
          background: 'lightgreen',
          borderRadius: '5px',
        }
      }
    ],
    detail: {
      avatar: 'thumbProductImage',
      info: [
        [
          {
            code: 'description',
            dataType: EnumDataType.Text,
            isOptions: false,
          }
        ],
        [
          {
            code: 'productUrl',
            dataType: EnumDataType.Link,
            isOptions: false,
          }
        ],
        [
          {
            code: 'quantity',
            dataType: EnumDataType.BigInt,
            isOptions: false,
            icon: 'shopping_cart',
            cellCss: {
              margin: '0 5px'
            }
          },
          {
            code: 'productPrice',
            dataType: EnumDataType.JPY,
            isOptions: false,
            icon: 'payments',
            cellCss: {
              margin: '0 5px'
            }
          }
        ]
      ]
    },
    bottom: [
      [
        {
          code: 'japanTrackingCode',
          dataType: EnumDataType.Text,
          isOptions: false,
          icon: 'local_shipping',
          cellCss: {
            margin: '0 5px'
          }
        }
      ]
    ],

    actions: (item: ProductOrderType) =>  item.progressStatus == EnumProductOrderProgressStatus.Waiting? [
      {
        icon: 'mode_edit',
        actionType: EnumAction.Edit,
        action: () => onEdit(item.productOrderId),
        buttonClass: 'info600',
        align: '',
      },
      {
        icon: 'delete',
        actionType: EnumAction.Delete,
        action: () => onDelete(item.productOrderId),
        buttonClass: 'info100',
        align: '',
      }
    ] : []
  };

  const tableDisplay = {
    header: [
      {
        code: 'thumbProductImage',
        dataType: EnumDataType.Image,
        isOptions: false,
        title: 'Ảnh',
      },
      {
        code: 'productName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên SP',
      },
      {
        code: 'productUrl',
        dataType: EnumDataType.Link,
        isOptions: false,
        title: 'Link SP',
      },
      {
        code: 'productPrice',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Giá SP',
      },
      {
        code: 'quantity',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Số lượng',
      },
      {
        code: 'description',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mô tả hàng hóa',
      },
      {
        code: 'japanTrackingCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã vận đơn JP',
      },
      {
        code: 'note',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Ghi chú',
      },
      {
        code: 'japanTrackingCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã vận đơn JP',
      },
      {
        code: 'progressStatus',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Trạng thái',
        options: lstStatus,
      },
    ],
    actions: (item: ProductOrderType) =>  item.progressStatus == EnumProductOrderProgressStatus.Waiting? [
      {
        icon: 'mode_edit',
        actionType: EnumAction.Edit,
        action: () => onEdit(item.productOrderId),
        buttonClass: 'info600',
        align: '',
      },
      {
        icon: 'delete',
        actionType: EnumAction.Delete,
        action: () => onDelete(item.productOrderId),
        buttonClass: 'info100',
        align: '',
      }
    ] : []
  };

  //Function
  const getMyProductOrder = useGetMyProductOrder();
  const addPopup = useAddPopup();

  const onClickLink = (link: string, blank: boolean) => {
    if (blank) {
      window.open(link);
    } else {
      navigate(link);
    }
  };

  const onDetail = (productOrderId: number) => {
    navigate(`/product-order/${productOrderId}/${EnumViewType.View}`);
  };

  //useEffect
  useEffect(() => {
    getMyProductOrder(keyword, page, size)
      .then((data) => {
        setProductOrderList(data.items);
        setCount(data.count);

      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getMyProductOrder, keyword, page, size, reloadFlag]);

//End of component
const onEdit = (productOrderId: number) => {
  detailModal.handlePresent(
    {
      productOrderId: productOrderId,
      isDisable: false,
      postProcess: (data: ProductOrderType) => {
        detailModal.handleDismiss();
        setReloadFlag(!reloadFlag);
      },
    },
    'THAY ĐỔI'
  );
};

const onAdd = (e: MouseEvent) => {
  detailModal.handlePresent(
    {
      isDisable: false,
      postProcess: (data: ProductOrderType) => {
        detailModal.handleDismiss();
        setReloadFlag(!reloadFlag);
      },
    },
    'THÊM MỚI'
  );
};

const onDelete = (productOrderId: number) => {
  const onConfirm = () => {
    deleteProductOrder(productOrderId)
      .then(() => {
        addPopup({
          txn: {
            success: true,
            summary: 'Xóa đơn mua hộ thành công!',
          },
        });
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      })
      .finally(() => setReloadFlag(!reloadFlag));
  };

  const listButton: EventButton[] = [
    {
      name: 'Xác nhận',
      icon: 'check',
      actionType: EnumAction.Confirm,
      action: onConfirm,
      buttonClass: 'info',
      align: 'center',
    },
    {
      name: 'Hủy',
      icon: 'clear',
      actionType: EnumAction.Cancel,
      buttonClass: 'info',
      align: 'center',
    },
  ];
  confirmModal.handlePresent(
    {
      question: 'Bạn có chắc muốn xóa đơn mua hộ này?',
      listActionButton: listButton,
      postProcess: confirmModal.handleDismiss,
    },
    'XÓA ĐƠN VẬN CHUYỂN',
  );
}

const listButton: EventButton[] = [
  {
    name: 'Thêm',
    icon: 'add',
    actionType: EnumAction.Add,
    buttonClass: 'info order-tool-btn',
    action: onAdd,
    align: 'center',
  }
];

  return (
    <>
      <ToolBar
        toolBarKey='JVSPRODUCTORDER'
        toolbarName='DANH SÁCH ĐƠN MUA HỘ'
        width={'100%'}
        listRightButton={listButton}
        backgroundColor={'#ebe9e9'}
        count={count}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
      />

      {window.innerWidth > 600? <Table
        display={tableDisplay}
        isInput={false}
        data={productOrderList}
        onDoubleClick={(item) => onDetail(item.productOrderId)}
        isNo={true}
      /> : <ListView
        display={display}
        isInput={false}
        data={productOrderList}
        onDoubleClick={(item) => onDetail(item.productOrderId)}
        isNo={true}
      />}
    </>
  );
};

export default ProductOrder;
